import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import Img from "react-optimized-image";

import "./Header.scss";

import Logo from "../../assets/img/jee-logo.png";
import Menu from "../../assets/img/menu.png";
import { useContext } from "react";
import { StoreContext } from "../../context/context";

const Header = (props) => {
  const [showDrop, setShowDrop] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const loggedIn = !!(state && state.user);
  // console.log(loggedIn);

  const {
    state: { user },
  } = useContext(StoreContext);

  const handleClick = (route) => {
    props.history.push(`${route}`);
  };

  const handleJEE = () => {
    window.open("https://www.jacksonettiandedu.com/");
  };

  return (
    <div className="header">
      <div className="header__content">
        <img alt="logo" src={Logo} onClick={() => handleClick("/")} />
        <div className="header__options">
          <img alt="menu" src={Menu} onClick={() => setShowDrop(!showDrop)} />

          <div className="options__desktop">
            <div onClick={handleJEE} className="option__desktop">
              JEE Website
            </div>

            <div className="option__desktop">
              <span
                className="redify"
                onClick={
                  loggedIn
                    ? () => {
                        props.history.push("/dashboard");
                      }
                    : null
                }
              >
                Regulatory Portal
              </span>
              {!loggedIn && (
                <span
                  className="iconify icon-space redify"
                  data-icon="zondicons:cheveron-down"
                  data-inline="false"
                ></span>
              )}

              {!loggedIn && (
                <ul>
                  {" "}
                  <li onClick={() => handleClick("/signin")}>Sign In</li>
                  <li onClick={() => handleClick("/signup")}>Sign Up</li>
                </ul>
              )}
            </div>

            {/* <div className="regulatory__portal">
              <button onClick={handleToggle}>Regulatory Portal</button>
              <ul className={`${showOptions ? "" : "no__display"}`}>
                {" "}
                <li onClick={() => handleClick("/signin")}>Sign In</li>
                <li onClick={() => handleClick("/signup")}>Sign Up</li>
              </ul>
            </div> */}

            {/* <button
              onClick={() => handleClick(`${user ? "/dashboard" : "/signin"}`)}
            >
              {user ? "Dashboard" : "Sign In"}
            </button> */}
            {/* <span>Africa Practice</span>
            <span>Expertice</span>
            <span>Insights</span>
            <span>Career</span> */}
            {/* <span onClick={() => handleClick("/contact-us")}>Contact Us</span> */}
          </div>
        </div>
      </div>
      <div className={`header__drop  ${showDrop ? "drop__show" : ""}`}>
        {/* <span>
          Africa Practice{" "}
          <span
            className="iconify icon-space"
            data-icon="zondicons:cheveron-down"
            data-inline="false"
          ></span>
        </span>
        <span>
          Expertice{" "}
          <span
            className="iconify icon-space"
            data-icon="zondicons:cheveron-down"
            data-inline="false"
          ></span>
        </span>
        <span>
          Insights{" "}
          <span
            className="iconify icon-space"
            data-icon="zondicons:cheveron-down"
            data-inline="false"
          ></span>
        </span>
        <span>
          Career{" "}
          <span
            className="iconify icon-space"
            data-icon="zondicons:cheveron-down"
            data-inline="false"
          ></span>
        </span>
        */}
        {/* <span>
          Contact Us{" "}
          <span
            className="iconify icon-space"
            data-icon="zondicons:cheveron-down"
            data-inline="false"
            onClick={() => handleClick("/contact-us")}
          ></span>
        </span> */}
        <span onClick={handleJEE}>JEE Website </span>
        <div className="mobile__options">
          <div>
            <span
              onClick={
                loggedIn
                  ? () => {
                      props.history.push("/dashboard");
                    }
                  : () => {
                      setShowOptions((prev) => !prev);
                    }
              }
              className="redify"
            >
              Regulatory Portal
            </span>

            {!loggedIn && (
              <span
                className="iconify icon-space redify"
                data-icon="zondicons:cheveron-down"
                data-inline="false"
                onClick={() => {
                  setShowOptions((prev) => !prev);
                }}
              ></span>
            )}
          </div>
          {!loggedIn && (
            <ul className={`${showOptions ? "mobile__show" : "mobile__hide"}`}>
              <li onClick={() => handleClick("/signin")}>Sign In</li>
              <li onClick={() => handleClick("/signup")}>Sign Up</li>
            </ul>
          )}
        </div>
        {/* <span onClick={() => handleClick("/signin")}>Sign In</span>
        <span onClick={() => handleClick("/signup")}>Sign Up</span> */}
        {/* <button
          onClick={() => handleClick(`${user ? "/dashboard" : "/signin"}`)}
        >
          {user ? "Dashboard" : "Sign In"}
        </button> */}
      </div>
    </div>
  );
};

export default withRouter(Header);
