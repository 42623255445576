import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { nameArray, slugArray } from '../../utils/appVariables';

import Arrow from '../../assets/img/double-left-arrows-symbol.svg';

import './Sectors.scss';

import { StoreContext } from '../../context/context';

const Sectors = (props) => {
  const [pref, setPref] = useState(null);
  const handleClick = (index) => {
    props.history.push(`/dashboard/sectors/${slugArray[index]}`);
  };

  const { setActive } = useContext(StoreContext);

  const getPreferences = async () => {
    const state = JSON.parse(window.localStorage.getItem('jee__state__client'));
    const data = await fetch(
      'https://jacksonettiedu.herokuapp.com/user-preferences',
      {
        headers: {
          authorization: state.user.token,
        },
        method: 'GET',
      }
    );

    const parseData = await data.json();
    const mainData = parseData.data;

    const result = nameArray.reduce((acc, curr, index) => {
      return [
        ...acc,
        {
          title: curr,
          status: mainData.includes(slugArray[index]),
        },
      ];
    }, []);

    setPref(result);
  };

  useEffect(() => {
    setActive(1);
    getPreferences();
  }, []);

  return (
    <div className="sectors__container">
      <div className="sectors__headers">
        <h3>Sectors</h3>
        <p>
          We are focused on key sectors and have developed a reputation for
          understanding your business and for delivering innovative services
          that anticipate your needs
        </p>
      </div>
      <div className="sectors__gallery">
        {pref ? (
          <>
            {' '}
            {pref.map((entry, index) => {
              if (!entry.status) return;
              const position = nameArray.indexOf(entry.title);
              return (
                <div
                  key={index}
                  className={`sectors__sector ${slugArray[position]}`}
                >
                  <div
                    className="sector__main"
                    onClick={() => handleClick(index)}
                  >
                    <span>{entry.title}</span>
                    <img src={Arrow} alt="view" />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <ReactLoading
            type="bubbles"
            color="#ef3439"
            height={300}
            width={200}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(Sectors);
