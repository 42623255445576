import React, { useRef, useState, useContext, useEffect } from "react";
import swal from "sweetalert";
import LoadingBar from "react-top-loading-bar";

import "./UserCard.scss";

import Icon from "../../assets/img/user-avatar.png";
import Change from "../../assets/img/change.svg";
import { url } from "../../utils/appVariables";
import { StoreContext } from "../../context/context";

const UserCard = ({ user }) => {
  const { setUser } = useContext(StoreContext);
  const [Source, setSource] = useState(user.image);

  const [loadingSource, setLoadingSource] = useState(false);

  const fileInput = useRef();
  const ref = useRef();
  const handleFileUpload = () => {
    fileInput.current.click();
  };
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const handleInputChange = async (event) => {
    try {
      setLoadingSource(true);
      ref.current.continuousStart();
      const cloudinaryUrl = "https://api.Cloudinary.com/v1_1/etti/image/upload";
      const { files } = event.target;
      const fileUploaded = URL.createObjectURL(event.target.files[0]);
      // setSource(fileUploaded);

      // cloudinary
      const formData = new FormData();
      formData.append("file", files[0]);

      // replace this with your upload preset name
      formData.append("upload_preset", "xl5a8n7u");

      const options = {
        method: "POST",
        body: formData,
      };

      const data = await fetch(cloudinaryUrl, options);
      const use = await data.json();

      const server = await fetch(url + `save-user-profile-picture`, {
        method: "POST",
        headers: {
          authorization: state.user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_url: use.secure_url,
        }),
      });
      const rServer = await server.json();
      ref.current.complete();
      setLoadingSource(false);
    } catch (e) {
      ref.current.complete();
      setLoadingSource(false);
      swal(
        "oops!",
        "An error occured while trying to upload image, please try again",
        "warning"
      );
    }
  };

  useEffect(() => {
    let newUser = { ...state.user, image_url: user.image };
    setUser(newUser);
  }, [user.image]);
  return (
    <div className="usercard">
      <LoadingBar color="#f11946" ref={ref} />
      <div className="usercard__gallery">
        <img
          src={user.image || Icon}
          alt="passport"
          className={`"usercard__passport" ${loadingSource && "animate__img"}`}
        />
        <img
          src={Change}
          alt="change"
          className="usercard__change"
          onClick={handleFileUpload}
        />
        <input
          type="file"
          id="upload__file"
          ref={fileInput}
          onChange={handleInputChange}
          style={{ display: "none" }}
          accept=".png, .jpg, .jpeg"
        />
      </div>
      <p className="usercard__name">{user.name}</p>
      <p className="usercard__title">{user.title}</p>
    </div>
  );
};

export default UserCard;
