import React from 'react';
import { withRouter } from 'react-router-dom';

import './Intro.scss';
const Intro = (props) => {
  const handleClick = (route) => {
    props.history.push(`${route}`);
  };
  return (
    <div className="intro">
      <h3>Welcome to the Sector Hub</h3>
      <p>
        Unlock our suite of sector regulation(s) and legal trends when you need
        it.
      </p>
      {/* <button
        onClick={() => {
          handleClick("/signup");
        }}
      >
        Explore
      </button> */}
    </div>
  );
};

export default withRouter(Intro);
