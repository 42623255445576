import React, { useState } from "react";

import "react-responsive-modal/styles.css";
import "./Pdf.scss";

import { Document, Page, pdfjs } from "react-pdf";

import { Modal } from "react-responsive-modal";

import Iframe from "react-iframe";

const Pdf = ({ open, onClose, url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [showIcon, setShowIcon] = useState(false);
  const [isOpen, setIsOpen] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setShowIcon(true);
    setNumPages(nextNumPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };
  const classNames = {
    overlay: "modal__overlay",
    modal: "modal__modal",
  };

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoad = (page) => {
    const parentDiv = document.querySelector("#pdfDocument");
    let pageScale = parentDiv.clientWidth / page.originalWidth;
    if (scale !== pageScale) {
      setScale(pageScale);
    }
  };

  return (
    <div>
      <Modal
        open={isOpen === null ? open : isOpen}
        center
        showCloseIcon={false}
        classNames={classNames}
        onClose={onClose}
      >
        <div className="modelContent">
          {/* <Iframe url={url} className="iframe" loading="eager"></Iframe> */}
          {/* <a href={url}>example</a> */}
          {/* <iframe
            src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${url}#toolbar=0`}
            width="100%"
            height="500px"
          ></iframe> */}
          <div id="pdfDocument">
            {showIcon && (
              <div className="close__modal__container">
                <p className="close__modal__icon" onClick={() => onClose()}>
                  Close
                </p>
              </div>
            )}
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  onLoadSuccess={onPageLoad}
                  scale={scale}
                />
              ))}
            </Document>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Pdf;
