export const url = "https://jacksonettiedu.herokuapp.com/";

export const slugArray = [
  "real-estate-and-infrastructure",
  "fast-moving-consumer-goods",
  "financial-service",
  "health-and-pharmaceuticals",
  // "public-sector",
  "technology-media-entertainment",
];

export const nameArray = [
  "Energy & Infrastructure",
  "Fast Moving Consumer Goods",
  "Financial Services",
  "Health & Pharmaceuticals",
  // "Public Sector",
  "Technology, Media & Entertainment",
];

export const mapObject = slugArray.reduce((acc, curr, index) => {
  return {
    ...acc,
    [curr]: nameArray[index],
  };
}, {});

export const varNames = [
  "realEstate",
  "consumerGoods",
  "financialService",
  "healthAndPharmaceuticals",
  // "publicSector",
  "technology",
];

export const baseURL = "https://jacksonettiedu.herokuapp.com";

export const sectorsData = [
  {
    title: "Energy & Infrastructure",
    options: [
      {
        title: "Laws/ Regulations Instruments",
      },
      {
        title: "NewsLetter",
        url: "real-estate-and-infrastructure/newsletter",
      },
      {
        title: "Thoughts Leadership",
        url: "real-estate-and-infrastructure/thought",
      },
      {
        title: "Podcasts",
      },
    ],
  },
  {
    title: "Fast Moving Consumer Goods",
    options: [
      {
        title: "Laws/ Regulations Instruments",
      },
      {
        title: "NewsLetter",
        url: "fast-moving-consumer-goods/newsletter",
      },
      {
        title: "Thoughts Leadership",
        url: "fast-moving-consumer-goods/thought",
      },
      {
        title: "Podcasts",
      },
    ],
  },
  {
    title: "Financial Service",
    options: [
      {
        title: "Laws/ Regulations Instruments",
      },
      {
        title: "NewsLetter",
        url: "financial-service/newsletter",
      },
      {
        title: "Thoughts Leadership",
        url: "financial-service/thought",
      },
      {
        title: "Podcasts",
      },
    ],
  },
  {
    title: "Health & Pharmaceuticals",
    options: [
      {
        title: "Laws/ Regulations Instruments",
      },
      {
        title: "NewsLetter",
        url: "health-and-pharmaceuticals/newsletter",
      },
      {
        title: "Thoughts Leadership",
        url: "health-and-pharmaceuticals/thought",
      },
      {
        title: "Podcasts",
      },
    ],
  },
  // {
  //   title: "Public Sector",
  //   options: [
  //     {
  //       title: "Laws/ Regulations Instruments",
  //     },
  //     {
  //       title: "NewsLetter",
  //       url: "public-sector/newsletter",
  //     },
  //     {
  //       title: "Thoughts Leadership",
  //       url: "public-sector/thought",
  //     },
  //     {
  //       title: "Podcasts",
  //     },
  //   ],
  // },
  {
    title: "Technology, Media & Entertainment",
    options: [
      {
        title: "Laws/ Regulations Instruments",
      },
      {
        title: "NewsLetter",
        url: "technology-media-entertainment/newsletter",
      },
      {
        title: "Thoughts Leadership",
        url: "technology-media-entertainment/thought",
      },
      {
        title: "Podcasts",
      },
    ],
  },
];
