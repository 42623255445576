import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Array from "../Array/Array";
import Intro from "../Intro/Intro";
import HomeSectors from "../HomeSectors/HomeSectors";

const Home = (props) => {
  const { state } = props.location;

  const [page, setPage] = useState((state && state.page) || 1);
  const [sector, setSector] = useState((state && state.sector) || "");
  const [sectorName, setSectorName] = useState(
    (state && state.sectorName) || ""
  );
  const [back, setBack] = useState(false);
  const [resetView, setResetView] = useState(false);
  const [sectorView, setSectorView] = useState(
    (state && state.sectorView) || false
  );

  const setVariables = (sector, name, page, resetView) => {
    setSector(sector);
    setSectorName(name);
    setResetView(resetView);
    setPage(page);
  };

  useEffect(() => {
    page == 1 && props.history.replace();
  }, [page]);

  return (
    <div className="home">
      <Intro />
      {page === 1 ? (
        <HomeSectors handleRoute={setVariables} back={back} />
      ) : (
        <div>
          <Array
            home={{ sector }}
            sectorName={sectorName}
            setPage={setPage}
            resetView={resetView}
            sectorView={sectorView}
          />
          <h5
            style={{
              color: "#ef3439",
              paddingLeft: "50px",
              cursor: "pointer",
              fontSize: "1em",
              marginTop: "45px",
            }}
            onClick={() => {
              setVariables("", "", 1, false);
              setBack(true);
            }}
          >
            <i
              style={{
                position: "relative",
                top: "-1px",
                marginRight: "2px",
              }}
            >
              &#x3c;
            </i>{" "}
            Back
          </h5>
        </div>
      )}
    </div>
  );
};

export default withRouter(Home);
