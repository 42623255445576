import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Truncate from "react-truncate-html";
import Pdf from "../Pdf/Pdf";
import { slugArray, nameArray, url } from "../../utils/appVariables";

import "./News.scss";

import PdfIcon from "../../assets/img/pdf-file.svg";
import NewsUpdate from "../NewsUpdate/NewsUpdate";
import Socials from "../Socials/Socials";

const News = (props) => {
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const home = !(state && state.user);
  const [open, setOpen] = useState(false);

  const transform = (node, index) => {
    // console.log(node, index);
  };

  const {
    id,
    summary,
    topic,
    imageUrl,
    pdfUrl,
    details,
    sector,
    textDate,
    contentType,
  } = props.location.state.data;

  const { category } = useParams();
  const index = slugArray.indexOf(category);

  const slugName =
    contentType === "newsletter" ? "Newsletter" : "Thought Leadership";
  const slugUrl =
    contentType === "newsletter" ? "newsletter" : "thought-leadership";

  const handleSlugRoute = () => {
    props.history.push(`/category/${sector}/${slugUrl}`);
  };

  const handleDownload = () => {
    let a = document.createElement("a");
    a.href = pdfUrl;
    a.download = `${topic}`;
    a.target = "_blank";
    a.click();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div className="news__container">
        <div className="news">
          <div className="news__main">
            <div className="news__content">
              <div className="news__breadcrumb">
                <strong
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleSlugRoute}
                >
                  {nameArray[index]}
                </strong>{" "}
                \ <span>{slugName}</span>
              </div>
              <section className="news__header">
                <p>{textDate}</p>
                <h3>{topic}</h3>
                {pdfUrl && (
                  <div className="news__download" onClick={handleDownload}>
                    <img src={PdfIcon} alt="download_icon" />{" "}
                    <span>Download Pdf</span>
                  </div>
                )}
              </section>
              <article className="news__article">
                {pdfUrl ? (
                  <>
                    <Truncate
                      lines={20}
                      dangerouslySetInnerHTML={{
                        __html: details,
                      }}
                    />
                    <h4 onClick={() => setOpen(true)}>Read More</h4>
                    <p className="border__line"></p>
                  </>
                ) : (
                  <>{ReactHtmlParser(details)}</>
                )}
              </article>
            </div>
          </div>
          <Pdf open={open} onClose={onCloseModal} url={pdfUrl} />
          <div className="news__update">
            <NewsUpdate />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(News);
