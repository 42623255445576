import React, { useState } from "react";
// import Img from "react-optimized-image";

import "./FormControl.scss";

const FormControl = ({
  title,
  type,
  placeholder,
  image,
  item,
  setItem,
  index,
  handleChange,
  account,
  disabled,
  className,
}) => {
  if (item === null) {
    item = "";
  }

  const [showPassword, setShowPassword] = useState(false);

  // console.log(showPassword, type);

  return (
    <div>
      {type !== "radio" ? (
        <div
          className={`formControl ${className} ${
            account && "account__formcontrol"
          }`}
        >
          <span className={!account ? "required" : "account"}>{title}</span>
          <div>
            {image && <img alt="icon" src={image} />}

            <input
              disabled={disabled ? true : false}
              type={
                type === "password"
                  ? showPassword
                    ? "name"
                    : "password"
                  : type
              }
              placeholder={placeholder}
              data-style="formControl"
              value={item}
              onChange={(e) => {
                setItem && setItem(e.target.value);
                handleChange && handleChange(index, e.target.value);
              }}
            />
            {type === "password" ? (
              <>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <span
                    class="iconify"
                    data-icon="clarity-eye-hide-line"
                    data-inline="false"
                  ></span>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="radioBtn">
          <span>{title}</span>
          <div className={`radioBtn__container ${disabled && "textcursor"} `}>
            <label className="container">
              Male
              <input
                type="radio"
                name="radio"
                disabled={disabled ? true : false}
                checked={item === "male" ? true : false}
                onChange={(e) => {
                  const value = e.target.checked ? "male" : "female";
                  handleChange(index, value);
                }}
              />
              <span className="checkmark"></span>
            </label>
            <label className="container">
              Female
              <input
                disabled={disabled ? false : true}
                type="radio"
                name="radio"
                disabled={disabled ? true : false}
                checked={item === "female" ? true : false}
                onChange={(e) => {
                  const value = e.target.checked ? "female" : "male";
                  handleChange(index, value);
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormControl;
