import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Pdf from "../Pdf/Pdf";

import "./Recent.scss";

const Recent = ({ recent, history }) => {
  const { success, data, message } = recent;

  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);

  const handleRoute = (sector) => {
    history.push({
      pathname: `/category/${sector}/laws-and-regulations`,
    });
  };
  
  const onCloseModal = () => setOpen(false);

   const handleOpenModal = (contentUrl) => {
    setUrl(contentUrl);
    setOpen(true);
  };

  return (
    <div className="recent">
      <h3>Recently Viewed :</h3>
      <div className="recent__main">
        {success ? (
          <>
            {data.map((law, index) => (
              <p key={index} onClick={() => {handleOpenModal(law.url) }}>
                {law.lawName}
              </p>
            ))}
          </>
        ) : (
          <p>{message}</p>
        )}
      </div>
       {<Pdf open={open} onClose={onCloseModal} url={url} />}
    </div>
  );
};

export default withRouter(Recent);
