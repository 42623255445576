import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import ReactLoading from "react-loading";

import Search from "../../assets/img/3028692271535694869.svg";
import LawImage from "../../assets/img/laws-image.png";
import NewsUpdate from "../NewsUpdate/NewsUpdate";
import PaginateLaws from "../PaginateLaws/PaginateLaws";

import { nameArray, slugArray } from "../../utils/appVariables";

import { getNewsData } from "../../utils/helpers";

import "./Laws.scss";
import swal from "sweetalert";

const Laws = ({ history, home }) => {
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const { category } = useParams();
  const index = slugArray.indexOf(category);

  const getData = async () => {
    setData(null);
    try {
      const content = await getNewsData(0, "laws", category, 12);
      setData(content.data);
      setHasSearched(false);
    } catch (e) {
      swal("Oops!", "Something went wrong!", "error");
      setHasSearched(false);
    }
  };

  const handleSearch = async (page, omit) => {
    setHasSearched(true);
    omit && setData(null);
    try {
      const state = JSON.parse(
        window.localStorage.getItem("jee__state__client")
      );

      const data = await fetch("https://jacksonettiedu.herokuapp.com/search", {
        headers: {
          authorization: state.user.token,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          title: searchText,
          sector: category,
          type: "laws-and-regulation",
          page: page,
          size: 12,
        }),
      });
      const parsed = await data.json();
      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }

      setData(parsed.data);
    } catch (e) {
      getData();
      swal("Oops!", `${e.customMessage || "Something went wrong!"}`, "warning");
    }
  };

  const handlePageClick = async (obj) => {
    if (data.search) {
      handleSearch(obj.selected, false);
    } else {
      try {
        const content = await getNewsData(obj.selected, "laws", category, 12);
        setData(content.data);
        setHasSearched(false);
      } catch (e) {
        setHasSearched(false);
        swal("Oops!", "Something went wrong!", "error");
      }
    }
  };

  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const loggedIn = !!(state && state.user);

  const handleSlugRoute = () => {
    if (loggedIn) {
      history.push(`/dashboard/sectors/${slugArray[index]}`);
    } else {
      history.push({
        pathname: "/",
        state: {
          sector: slugArray[index],
          page: 2,
          sectorView: true,
          sectorName: nameArray[index],
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [category]);

  useEffect(() => {
    if (hasSearched && searchText === "") {
      getData();
    }
  }, [searchText]);

  return (
    <div className="laws">
      <img alt="laws" src={LawImage} />
      <div className="laws__searchrow">
        <span className="detail">
          <strong style={{ cursor: "pointer" }} onClick={handleSlugRoute}>
            {nameArray[index]}
          </strong>{" "}
          \ <span>Laws & Regulations</span>
        </span>{" "}
        <span className="searchbox">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(0, true);
            }}
          >
            <input
              type="text"
              placeholder="Search Laws"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <img
              src={Search}
              alt="search"
              onClick={() => handleSearch(0, true)}
            />
          </form>
        </span>
      </div>
      <div className="laws__content">
        <div className="lawsPaginate__container">
          {data ? (
            <PaginateLaws
              data={data}
              handlePageClick={handlePageClick}
              type="lawsAndRegulations"
            />
          ) : (
            <ReactLoading
              type="bubbles"
              color="#ef3439"
              height={300}
              width={200}
            />
          )}
        </div>
        <NewsUpdate />
      </div>
    </div>
  );
};

export default withRouter(Laws);
