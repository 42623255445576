import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
// import Img from "react-optimized-image";

import "./DashboardDesktopHeader.scss";

import Icon from "../../assets/img/user-avatar.png";
import Sector from "../../assets/img/sections.svg";
import { StoreContext } from "../../context/context";

const DashboardDesktopHeader = (props) => {
  const { state } = useContext(StoreContext);
  const handleClick = (route) => {
    props.history.push(`${route}`);
  };

  return (
    <div className="ddh">
      <section
        className="sectors"
        onClick={() => handleClick("/dashboard/sectors")}
      >
        <img src={Sector} alt="sectors" /> Sectors
      </section>
      <section className="image">
        <img
          src={state.user.image_url || Icon}
          alt="profile picture"
          onClick={() => handleClick("/dashboard/account")}
        />
      </section>
    </div>
  );
};

export default withRouter(DashboardDesktopHeader);
