import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { nameArray, slugArray } from "../../utils/appVariables";
import ImageGallery from "../ImageGallery/ImageGallery";

import "./Sector.scss";

import { StoreContext } from "../../context/context";

const Sector = (props) => {
  const slug = props.match.params.sector;
  const title = nameArray[slugArray.indexOf(slug)];

  const { setActive } = useContext(StoreContext);

  useEffect(() => {
    setActive(-1);
  }, []);

  return (
    <div className="sector">
      <div className="sector__breadcrumb">
        <span
          style={{ cursor: "pointer" }}
          className="strong__slug"
          onClick={() => props.history.push("/dashboard/sectors")}
        >
          Sectors
        </span>
        <span> / {title}</span>
      </div>
      <p>
        To meet your unique needs, we pair our renowned legal expertise with
        in-depth knowledge of your industry sector.
      </p>
      <div className="sector__gallery">
        <ImageGallery small={true} slug={slug} />
      </div>
    </div>
  );
};

export default withRouter(Sector);
