import React from "react";

import "./Tabs.scss";

const Tabs = ({ tab }) => {
  return (
    <div className="tabs">
      <div className={`tabs__circle ${tab >= 1 ? "activeNow" : ""}`}>
        <span>1</span>
        <span>Preferences</span>
      </div>
      <div className={`tabs__hr ${tab >= 1 ? "activeNow" : ""}`}></div>
      <div className={`tabs__circle ${tab >= 2 ? "activeNow" : ""}`}>
        <span>2</span>
        <span className="special">About You</span>
      </div>
      <div className={`tabs__hr ${tab === 3 ? "activeNow" : ""}`}></div>
      <div className={`tabs__circle ${tab === 3 ? "activeNow" : ""}`}>
        <span>3</span>
        <span>Done</span>
      </div>
    </div>
  );
};

export default Tabs;
