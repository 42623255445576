import React from "react";
import { withRouter } from "react-router-dom";
import "./AboutYou.scss";

import Name from "../../assets/img/man.svg";
import Mail from "../../assets/img/email (1).svg";
import Lock from "../../assets/img/padlock.svg";
import Company from "../../assets/img/office-building.svg";

import FormControl from "../FormControl/FormControl";

const AboutYou = ({
  name,
  setName,
  email,
  setEmail,
  // password,
  // setPassword,
  cname,
  setCname,
  history,
}) => {
  const handleClick = (route) => {
    history.push(`${route}`);
  };

  return (
    <div className="about">
      <h3>Sign Up</h3>
      <FormControl
        title="Fullname"
        type="name"
        placeholder="Enter Fullname"
        image={Name}
        item={name}
        setItem={setName}
      />
      <FormControl
        title="Email"
        type="email"
        placeholder="Enter Email"
        image={Mail}
        item={email}
        setItem={setEmail}
      />
      {/* <FormControl
        title="Password"
        type="password"
        placeholder="Enter Password"
        image={Lock}
        item={password}
        setItem={setPassword}
      /> */}
      <FormControl
        title="Company Name"
        type="name"
        placeholder="Enter Company Name"
        image={Company}
        item={cname}
        setItem={setCname}
      />
      <p>
        Already have an account?{" "}
        <a href="#" onClick={() => handleClick("/signin")}>
          Sign in
        </a>
      </p>
    </div>
  );
};

export default withRouter(AboutYou);
