import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Modal } from "react-responsive-modal";

import Menu from "../../assets/img/menu.png";
import Logo from "../../assets/img/Capture-removebg-preview.png";
import Icon from "../../assets/img/user-avatar.png";
import CloseIcon from "../../assets/img/close-24px.svg";
import ExitIcon from "../../assets/img/exit.svg";

import "./Sidebar.scss";

import { StoreContext } from "../../context/context";
import Feedback from "../Feedback/Feedback";

const Sidebar = (props) => {
  const [open, setOpen] = useState(false);
  const [openM, setOpenM] = useState(false);

  const {
    setUser,
    state: { user },
  } = useContext(StoreContext);

  const handleCloseNav = () => {
    setOpen(false);
  };
  const handleToggleNav = () => {
    setOpen((prev) => !prev);
  };
  const handleClick = (route) => {
    setOpen(false);
    props.history.push(`${route}`);
  };

  const handleFeedbackClose = () => {
    setOpenM(false);
    setUser(null);
    props.history.push("/");
  };

  const handleExit = () => {
    setOpen(false);
    setOpenM(true);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("sidebar__opened");
    } else {
      document.body.classList.remove("sidebar__opened");
    }
  }, [open]);

  return (
    <>
      <Modal
        open={openM}
        onClose={handleFeedbackClose}
        center
        closeIconId="modal__closeicon"
        classNames={{
          overlay: "feedbackOverlay",
          modal: "feedbackModal",
        }}
      >
        <Feedback closeHandler={handleFeedbackClose}></Feedback>
      </Modal>
      <div className="sidebar">
        <header className="sidebarHeader">
          <img
            alt="logo"
            src={Logo}
            className="logo__img"
            onClick={() => props.history.push("/")}
          />{" "}
          <img
            alt="menu"
            src={Menu}
            onClick={handleToggleNav}
            className="menu__img"
          />
        </header>

        <section className={`sideNav ${open ? "open" : ""}`}>
          <div className="sideNav__header">
            <img
              alt="close"
              src={CloseIcon}
              className="close__icon"
              onClick={handleCloseNav}
            />
            <img
              alt="user"
              src={user.image_url || Icon}
              className="user__icon"
              onClick={() => handleClick("/dashboard/account")}
            />
          </div>

          <div className={`nav__list show__list`}>
            <span
              className="nav__item"
              onClick={() => handleClick("/dashboard")}
            >
              Home
            </span>
            <span
              className="nav__item"
              onClick={() => handleClick("/dashboard/sectors")}
            >
              Sectors
            </span>
            {/* <span className="nav__item" onClick={() => handleClick("/dashboard/blog")}>Blog</span> */}
            <span
              className="nav__item"
              onClick={() => handleClick("/dashboard/preferences")}
            >
              Preferences
            </span>
            <span
              className="nav__item"
              onClick={() => handleClick("/dashboard/account")}
            >
              Account
            </span>
            <span
              className="nav__item"
              onClick={() => handleClick("/contact-us")}
            >
              Contact Us
            </span>
          </div>

          <div className="nav__footer">
            <div className={` footer__main  show__footer`}>
              <img alt="Exit" src={ExitIcon} className="close__icon" />
              <span className="footer__detail" onClick={handleExit}>
                Logout
              </span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
