import React from "react";

import "./ShowList.scss";

const ShowList = ({ email, data, title }) => {
  return (
    <div className="showlist">
      <h2>{title}</h2>
      <div className="showlist__main">
        {data.map((entry, index) => (
          <span key={index}>{entry}</span>
        ))}
      </div>
      <p className="showlist__footer">{email}</p>
    </div>
  );
};

export default ShowList;
