import React from "react";
// import Img from "react-optimized-image";
import Truncate from "react-truncate";

import "./FullCard.scss";

import { Link } from "react-router-dom";

import Mat from "../../assets/img/pexels-belle-co-342008.png";

const FullCard = ({ data, contentType }) => {
  const {
    id,
    summary,
    topic,
    imageUrl,
    pdfUrl,
    details,
    updatedAt,
    sector,
    textDate,
  } = data;

  return (
    <Link
      to={{
        pathname: `/content/${sector}/${id}`,
        state: {
          data: { ...data, contentType },
        },
      }}
    >
      <div className="fullcard">
        <div className="fullcard__image">
          <img src={imageUrl} alt="desc" />
        </div>
        <div className="fullcard__details">
          <h2>{topic} </h2>
          <p>{textDate}</p>
          <p>
            <Truncate lines={4} ellipsis={<span>...</span>}>
              {summary}
            </Truncate>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default FullCard;
