import React, { useRef, useEffect } from "react";
import ImageGallery from "../ImageGallery/ImageGallery";
import "./Array.scss";

const Array = ({ home, sectorName, setPage, resetView, sectorView }) => {
  const ref = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    resetView && ref2.current.scrollIntoView({ behaviour: "smooth" });
    sectorView && ref2.current.scrollIntoView({ behaviour: "smooth" });
  }, []);

  return (
    <div className="array">
      <h3 ref={ref2}>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setPage(1)}
          className="strong__slug"
        >
          Sectors{" "}
        </span>
        <span style={{ fontWeight: "normal" }}>/ {sectorName}</span>
      </h3>
      <p>
        We distinguish ourselves through the depth of our knowledge in each of
        our practice areas. This enables our clients to call on a rare blend of
        legal and commercial understanding.
      </p>
      <h5>
        To meet your unique needs, we pair our renowned legal expertise with
        in-depth knowledge of your industry sector.
      </h5>
      <div className="array__images" ref={ref}>
        <ImageGallery home slug={home.sector} />
      </div>
    </div>
  );
};

export default Array;
