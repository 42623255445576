import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import "./Dashboard.scss";

import DashboardDesktopHeader from "../../components/DashboardDesktopHeader/DashboardDesktopHeader";
import DashboardHome from "../../components/DashboardHome/DashboardHome";
import DesktopSidebar from "../../components/DesktopSidebar/DesktopSidebar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Sectors from "../../components/Sectors/Sectors";
import Blogs from "../../components/Blogs/Blogs";
import Sector from "../../components/Sector/Sector";
import Preferences from "../../components/Preferences/Preferences";
import Account from "../../components/Account/Account";

const Dashboard = (props) => {
  const { match } = props;

  return (
    <div className="dashboard">
      <DesktopSidebar />
      <div className="dashboard__header">
        <Sidebar />
        <DashboardDesktopHeader />
      </div>
      <div className="dashboard__content">
        <Switch>
          <Route exact path={`${match.url}`} component={DashboardHome} />
          <Route
            exact
            path={`${match.url}/preferences`}
            component={Preferences}
          />
          <Route exact path={`${match.url}/sectors`} component={Sectors} />
           <Route exact path={`${match.url}/blog`} component={Blogs} />

          <Route
            exact
            path={`${match.url}/sectors/:sector`}
            component={Sector}
          />
          <Route exact path={`${match.url}/account`} component={Account} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Dashboard);
