import React from "react";
import Footer from "../Footer/Footer";
import NewsHeader from "../NewsHeader/NewsHeader";
import ShowList from "../ShowList/ShowList";
import Sidebar from "../Sidebar/Sidebar";

import "./ContactUs.scss";

const ContactUs = () => {
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const displayData = [
    {
      title: "Lagos",
      data: [
        "RCO Court",
        "3-5 Sinari Daranijo Street,",
        "Off Ajose Adeogun,",
        "Victoria Island,",
        "Lagos, Nigeria.",
        "Tel : +234-1-4626841/3, +234-1-2806989",
        "Fax : +234-1-2716889",
      ],
      email: "jacksonettiedu@jacksonettiandedu.com",
    },
    {
      title: "Abuja",
      data: [
        "42, Moses Majekodunmi Crescent,",
        "Utako,",
        "Federal Capital Territory,",
        "Abuja",
      ],
      email: "jacksonettiedu@jacksonettiandedu.com",
    },
    {
      title: "Ikeja",
      data: [
        "15 Efunleye Street,",
        "Ikeja, Lagos.",
        "P.O.Box 52055,",
        "Ikoyi, Lagos.",
      ],
      email: "jacksonettiedu@jacksonettiandedu.com",
    },
    {
      title: "Ghana",
      data: [
        "3 Emmause,",
        "2nd Close,",
        "Akosombo House,",
        "Labone,",
        "Accra, Ghana",
        "P.O.Box 14951,",
        "Accra, Ghana",
      ],
      email: "jacksonettiedu@jacksonettiandedu.com",
    },
    {
      title: "Harare",
      data: ["38 Clairwood Road,", "Alexandra Park,", "Harare,", "Zimbabwe."],
      email: "jacksonettiedu@jacksonettiandedu.com",
    },
    {
      title: "Yaounde",
      data: [
        "3rd Floor, Viccui Building,",
        "Apt. 15-16, Carr Street,",
        "New Town, Yaoundé,",
        "Cameroon.",
      ],
      email: "jacksonettiedu@jacksonettiandedu.com",
    },
  ];

  const home = !(state && state.user);

  return (
    <>
      <NewsHeader />
      <Sidebar />
      <div className="contactus">
        <header className="contactus__header">
          <h1> Contact JEE</h1>
          <p>We look forward to working with you</p>
        </header>
        <section>
          <h3>OUR OFFICES</h3>
          <span className="contactus__hr"></span>
          <div className="contactus__addresses">
            {displayData.map((entry, index) => (
              <ShowList
                email={entry.email}
                title={entry.title}
                data={entry.data}
                key={index}
              />
            ))}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
