import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import "./NewsUpdate.scss";

const NewsUpdate = (props) => {
  const [data, setData] = useState(null);
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));

  const fetchData = async () => {
    try {
      const rdata = await fetch(
        "https://jacksonettiedu.herokuapp.com/dashboard-latest-resource",
        {
          headers: {
            authorization: "Bearer",
          },
          method: "GET",
        }
      );

      const parsed = await rdata.json();
      setData(parsed.data);
    } catch (e) {}
  };

  const handleRoute = (resource) => {
    props.history.push({
      pathname: `/content/${resource.sector}/${resource.id}`,
      state: { data: resource },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="newsUpdate">
      {data && (
        <>
          <h3 className="newsUpdate__header">News and Updates</h3>
          {data.map((entry, index) => {
            const { imageUrl, topic, textDate } = entry;
            return (
              <div className="newsUpdate__content" key={index}>
                <img src={imageUrl} alt={"news"} />
                <p>{textDate}</p>
                <h3>{topic}</h3>
                <h5 onClick={() => handleRoute(entry)}>Read More</h5>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default withRouter(NewsUpdate);
