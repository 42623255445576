import React from "react";
import { withRouter } from "react-router-dom";

import "./Resources.scss";

const Resources = ({ resources, history }) => {
  const { success, data, message } = resources;
 
  const handleRoute = (resource) => {
    const newResource = { ...resource, contentType: resource.type };
    history.push({
      pathname: `/content/${resource.sector}/${resource.id}`,
      state: { data: newResource },
    });
  };

  const handleClick = (route) => {
    history.push(route);
  };

  return (
    <div className="resources">
      <h3 className="resources__title">Latest Resources</h3>
      {success ? (
        <>
          <div className="resources__main">
            {data.map((resource) => (
              <div className="resources__details" key={resource.id}>
                <p>{resource.topic}</p>
                <h5
                  className="resources__more"
                  onClick={() => handleRoute(resource)}
                >
                  Read More
                </h5>
              </div>
            ))}
          </div>
          <div className="resources__footer">
            <span onClick={() => handleClick("/dashboard/sectors")}>
              View all
            </span>{" "}
            &#187;
          </div>
        </>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default withRouter(Resources);
