import React from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import Laws from "../../components/Laws/Laws";
import News from "../../components/News/News";

import NewsHeader from "../../components/NewsHeader/NewsHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Sidebar from "../../components/Sidebar/Sidebar";
import Thought from "../../components/Thought/Thought";
import Blog from "../../components/Blog/Blog";
import Podcasts from "../../components/Podcasts/Podcast";
import Webinars from "../../components/Webinars/Webinars";

const Category = (props) => {
  const home = props.match.url.includes("home");
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));

  return (
    <div>
      <NewsHeader />
      {state && state.user && <Sidebar />}
      <Switch>
        <Route
          exact
          path={`${props.match.url}/:category/thought`}
          render={(props) => <Thought {...props} home={home} />}
        />
        <Route
          exact
          path={`${props.match.url}/:category/thought-leadership`}
          render={(props) => <Thought {...props} home={home} />}
        />

        <Route
          exact
          path={`${props.match.url}/:category/newsletter`}
          render={(props) => <NewsLetter {...props} home={home} />}
        />

        <Route
          exact
          path={`${props.match.url}/:category/laws-and-regulations`}
          render={(props) => <Laws {...props} home={home} />}
        />
        <Route
          exact
          path={`${props.match.url}/:category/webinars`}
          render={(props) => <Webinars {...props} home={home} />}
        />
        <Route
          exact
          path={`${props.match.url}/:category/blog`}
          component={Blog}
        />
        <Route
          exact
          path={`${props.match.url}/:category/podcast`}
          component={Podcasts}
        />
        <Route
          exact
          path={`${props.match.url}/:category/:id`}
          component={News}
        />
      </Switch>
    </div>
  );
};

export default withRouter(Category);
