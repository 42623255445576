import React, { useState } from "react";

import "./ToggleSwitch.scss";

const ToggleSwitch = ({ checked, handleChange, index }) => {
  return (
    <div className="toggleswitch">
      {" "}
      <label className="switch">
        <input
          type="checkbox"
          onChange={(e) => handleChange(index)}
          checked={checked}
        />
        <span className="slider round"></span>
      </label>
      <span className="switch__status">{checked ? "Added" : "Removed"}</span>
    </div>
  );
};

export default ToggleSwitch;
