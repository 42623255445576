import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { getIntialState, persistState } from "../utils/persist-state";

import { storeReducer } from "./reducer";

const STORAGE_KEY = "jee__state__client";

const initialState = getIntialState(STORAGE_KEY) || {
  user: null,
  activeTab: 0,
};

const valueContext = {
  state: initialState,
  setUser: () => {},
};

export const StoreContext = createContext(valueContext);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const setUserFunc = (userObject) => {
    dispatch({
      type: "SET_USER",
      payload: userObject,
    });
  };

  const setUser = useCallback(setUserFunc, []);

  const setActiveFunc = (activeNumber) => {
    dispatch({
      type: "SET_ACTIVE",
      payload: activeNumber,
    });
  };

  const setActive = useCallback(setActiveFunc, []);

  useEffect(() => {
    persistState(STORAGE_KEY, state);
  }, [state]);

  const providerValue = {
    state,
    setUser,
    setActive,
  };

  return (
    <StoreContext.Provider value={providerValue}>
      {children}
    </StoreContext.Provider>
  );
};
