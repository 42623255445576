import React from "react";
import ReactLoading from "react-loading";
import {
  useQuery,
  useQueryCache,
  QueryCache,
  ReactQueryCacheProvider,
} from "react-query";

import "./DashboardHome.scss";

import Acts from "../Acts/Acts";
import Calendar from "../Calendar/Calendar";
import Recent from "../Recent/Recent";
import Resources from "../Resources/Resources";

// variables
import { url } from "../../utils/appVariables";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { StoreContext } from "../../context/context";

const queryCache = new QueryCache();

// const state = JSON.parse(window.localStorage.getItem("jee__state__client"));

const CachedDashboardHome = () => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <DashboardHome />
    </ReactQueryCacheProvider>
  );
};


const DashboardHome = () => {

  const { setActive, state } = useContext(StoreContext);

  const handleGetFetch = (link) => {
  return fetch(url + link, {
    method: "GET",
    headers: {
      authorization: state.user.token,
    },
  });
};


  const initialFetch = async () => {
  const recent = handleGetFetch("recently-viewed");

  const latest = handleGetFetch("dashboard-latest-resource");

  const acts = handleGetFetch("recently-updated-laws");

  const dataArray = await Promise.allSettled([recent, latest, acts]);

  const responseArray = await Promise.allSettled(
    dataArray.map((eachPromise) => eachPromise.value.json())
  );

  const [recentlyViewed, latestResources, newActs] = responseArray.map(
    (entry) => entry.value
  );

  return {
    recentlyViewed,
    latestResources,
    newActs,
  };
};




function useDashboardData() {
  return useQuery("dashboardData", initialFetch);
}

  
  const { status, data, error, isFetching } = useDashboardData();
  const { recentlyViewed, latestResources, newActs } = data || {
    recentlyViewed: null,
    latestResources: null,
    newActs: null,
  };

  useEffect(() => {
    setActive(0);
  }, []);

  return (
    <>
      {status === "loading" || status === "error" ? (
        <ReactLoading type="bubbles" color="#ef3439" height={300} width={200} />
      ) : (
        <div className="dashboardHome">
          <div className="arc">
            <div className="a">
              <Acts acts={newActs} />
            </div>
            <div className="rc">
              <Recent recent={recentlyViewed} />
              <Calendar />
            </div>
          </div>

          <Resources resources={latestResources} />
        </div>
      )}
    </>
  );
};

export default CachedDashboardHome;
