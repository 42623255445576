import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import Img from "react-optimized-image";

import ReactLoading from "react-loading";

import "./Blog.scss";

import BlogImage from "../../assets/img/blog.svg";
import Search from "../../assets/img/3028692271535694869.svg";
import PaginateBlog from "../PaginateBlog/PaginateBlog";
import { getNewsData } from "../../utils/helpers";

import swal from "sweetalert";

const Blog = () => {
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const { category } = useParams();

  const getData = async () => {
    setData(null);
    try {
      const content = await getNewsData(0, "blog", category, 12);
      // console.log(content)
      setData(content.data);
      setHasSearched(false);
    } catch (e) {
      swal("Oops!", "Something went wrong!", "error");
      setHasSearched(false);
    }
  };

  const handleSearch = async (page, omit) => {
    setHasSearched(true);
    omit && setData(null);
    try {
      const state = JSON.parse(
        window.localStorage.getItem("jee__state__client")
      );

      const data = await fetch("https://jacksonettiedu.herokuapp.com/search", {
        headers: {
          authorization: state.user.token,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          title: searchText,
          sector: category,
          type: "blog",
          page: page,
          size: 12,
        }),
      });
      const parsed = await data.json();
      // console.log(parsed)
      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }

      setData(parsed.data);
    } catch (e) {
      getData();
      swal("Oops!", `${e.customMessage || "Something went wrong!"}`, "warning");
    }
  };

  const handlePageClick = async (obj) => {
    if (data.search) {
      handleSearch(obj.selected, false);
    } else {
      try {
        const content = await getNewsData(obj.selected, "blog", category, 12);
        setData(content.data);
        setHasSearched(false);
      } catch (e) {
        setHasSearched(false);
        swal("Oops!", "Something went wrong!", "error");
      }
    }
  };

  useEffect(() => {
    getData();
  }, [category]);

  useEffect(() => {
    if (hasSearched && searchText === "") {
      getData();
    }
  }, [searchText]);

  return (
    <div className="blog">
      <div className="blog__heading">
        <div className="blog__headImage">
          <img src={BlogImage} alt="blog" />
        </div>
        <div className="blog__headText">
          <p>
            A collection of stories about our people, our capabilities, our
            research, and the ever-changing face of our firm.
          </p>
        </div>
      </div>
      <div className="blog__search">
        <span className="blog__searchText">Browse all posts</span>
        <span className="blog__searchbox">
          <input
            type="text"
            placeholder="Search posts"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <img
            src={Search}
            alt="search"
            onClick={() => handleSearch(0, true)}
          />
        </span>
      </div>
      {data ? (
        <PaginateBlog
          data={data}
          type="blog"
          handlePageClick={handlePageClick}
        />
      ) : (
        <ReactLoading type="bubbles" color="#ef3439" height={300} width={200} />
      )}
    </div>
  );
};

export default Blog;
