import React, { useRef, useEffect } from 'react';

import HomeSector from '../HomeSector/HomeSector';

import './HomeSectors.scss';

const HomeSectors = ({ handleRoute }) => {
  const data = [
    {
      subHeading: 'Energy & Infrastructure',
      mainHeading: 'Energy delivered better ',
      desc: 'Investing in, developing, occupying and funding infrastructure assets has never been more complex. The asset class has established its ...',
      bgClass: 'energy',
      sector: 'real-estate-and-infrastructure',
    },
    {
      subHeading: 'Fast Moving Consumer Goods',
      mainHeading: 'Safeguarding your brand',
      desc: 'We have an exceptional track record of advising leading multi-national consumer brands in this sector on all aspects of ...',
      bgClass: 'fast-moving',
      sector: 'fast-moving-consumer-goods',
    },
    {
      subHeading: 'Financial Services',
      mainHeading: "Delivering finance for tomorrow's world",
      desc: 'Trusted advisor to leading banks and other financial institutions accross several verticals',
      bgClass: 'financial',
      sector: 'financial-service',
    },
    {
      subHeading: 'Health & Pharmaceuticals',
      mainHeading: 'Innovation reimagined',
      desc: 'We have not only demonstrated knowledge and skill but can also be trusted to deliver quality and reliable counsel',
      bgClass: 'health',
      sector: 'health-and-pharmaceuticals',
    },
    {
      subHeading: 'Technology, Media & Entertainment',
      mainHeading: "Shaping tomorrow's future",
      desc: 'Whether you are a tech giant, a supplier to, or customer of, the tech industry, or a start up ...',
      bgClass: 'technology',
      sector: 'technology-media-entertainment',
    },
  ];

  const ref = useRef(null);

  useEffect(() => {
    // sectorView && ref.current.scrollIntoView({ behaviour: "smooth" });
  }, []);

  return (
    <div className="homesectors__container" ref={ref} id="sectors">
      <h3>Sectors</h3>
      <p>
        For you, our sector approach means working with advisors who are fully
        focused on your sector, wholly understanding the challenges that you
        face and completely tuned in to your needs. For our lawyers, it means
        offering both legal and commercial expertise to clients.
      </p>
      <h5>
        We are focused on key sectors and have developed a reputation for
        understanding your business and for delivering innovative services that
        anticipate your needs.
      </h5>
      <div className="homesectors">
        {data.map((entry, index) => {
          return (
            <HomeSector
              key={index}
              data={entry}
              index={index}
              onClick={() =>
                handleRoute(data[index].sector, data[index].subHeading, 2, true)
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default HomeSectors;
