import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import "./NewsHeader.scss";

import Logo from "../../assets/img/jee-logo.png";
import Sector from "../../assets/img/sections.svg";

const NewsHeader = (props) => {
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));

  const handleClick = (route) => {
    props.history.push(`${route}`);
  };

  const dataRegular = [
    {
      title: "Home",
      url: "/dashboard",
    },
    // {
    //   title: "Blog",
    //   url: "/dashboard/blog",
    // },
    {
      title: "Preferences",
      url: "/dashboard/preferences",
    },
    {
      title: "Sectors",
      img: Sector,
      alt: "sector",
      url: "/dashboard/sectors",
    },
  ];

  const dataHome = [
    {
      title: "Home",
      url: "/",
    },
    // {
    //   title: "Blog",
    //   url: "/dashboard/blog",
    // },
    // {
    //   title: "Preferences",
    //   url: "/dashboard/preferences",
    // },
    {
      title: "Sectors",
      alt: "sector",
      url: "/#sectors",
    },
  ];
  const home = !(state && state.user);
  const data = home ? dataHome : dataRegular;

  return (
    <div className={`newsheader ${home && "newsheader__home"} `}>
      <div className="newsheader__image">
        <img src={Logo} alt="logo" onClick={() => props.history.push("/")} />
      </div>

      <div className="newsheader__options">
        {data.map((entry, index) => {
          if (entry.img)
            return (
              <a
                className="newheader__option--bg"
                href={`${entry.url}`}
                // onClick={() => handleClick(entry.url)}
                key={index}
              >
                <img src={entry.img} alt={entry.alt} />
                <span>{entry.title}</span>
              </a>
            );
          return (
            <a
              className="newsheader__option"
              key={index}
              href={`${entry.url}`}
              // onClick={() => handleClick(entry.url)}
            >
              {entry.title}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(NewsHeader);
