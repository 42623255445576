import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Modal } from "react-responsive-modal";

import swal from "sweetalert";
import LoadingBar from "react-top-loading-bar";

import Logo from "../../assets/img/jee-white-logo.png";
import FormControl from "../FormControl/FormControl";

import "./Signin.scss";

import Mail from "../../assets/img/email (1).svg";
import Lock from "../../assets/img/padlock.svg";
import Company from "../../assets/img/office-building.svg";

import Checkbox from "../Checkbox/Checkbox";
import CustomError from "../Error/Error";

import { StoreContext } from "../../context/context";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setOpen(false);
  };

  const disableResetButton = !newPassword || confirmPassword !== newPassword;

  const isDirty = confirmPassword !== "" && confirmPassword !== newPassword;

  const ref = useRef(null);

  const { setUser } = useContext(StoreContext);

  const disableNext = !email || !password;

  const handleClick = (route) => {
    props.history.push(`${route}`);
  };

  const handleSignin = async (e) => {
    e.preventDefault();
    ref.current.continuousStart();
    if (checked) {
      window.localStorage.setItem(
        "jee__user",
        JSON.stringify({
          email,
          password,
        })
      );
    }

    try {
      const login = await fetch("https://jacksonettiedu.herokuapp.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      // console.log("done");
      const response = await login.json();

      if (!response.success) {
        throw new CustomError(response.message);
      }

      setUser(response.data[0]);

      handleClick("/dashboard");
    } catch (e) {
      ref.current.complete();
      // Handle Error
      const successful = await swal({
        title: "Oops!",
        text: `${e.isCustomError ? e.message : "Something went wrong"}`,
        icon: "warning",
      });
      if (successful) {
        props.history.go(0);
        props.history.push("/");
      }
    }
  };

  const handleForgotPassword = async () => {
    ref.current.continuousStart();
    try {
      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/forgot-password",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            email: resetEmail,
          }),
        }
      );
      const parsed = await data.json();
      // console.log(parsed);
      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }
      ref.current.complete();
      swal("Success!", `A reset token has been sent to your email`, "success");
      setPage(2);
    } catch (e) {
      ref.current.complete();
      swal("Oops!", `${e.customMessage || "Something went wrong!"}`, "warning");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    ref.current.continuousStart();
    try {
      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/recover-password",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            email: resetEmail,
            recoveryCode: resetToken,
            newPassword,
          }),
        }
      );
      const parsed = await data.json();
      // console.log(parsed);
      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }
      ref.current.complete();
      swal("Success!", `Password changed successful`, "success");
      setResetEmail("");
      setConfirmPassword("");
      setResetToken("");
      setPage(1);
    } catch (e) {
      ref.current.complete();
      swal("Oops!", `${e.customMessage || "Something went wrong!"}`, "warning");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("jee__user")) {
      const { email, password } = JSON.parse(localStorage.getItem("jee__user"));
      setEmail(email);
      setPassword(password);
    }
  }, []);

  return (
    <div className="signup signin">
      <LoadingBar color="#f11946" ref={ref} />
      <div className="signin__logo">
        <img alt="Logo" src={Logo} onClick={() => handleClick("/")} />
      </div>
      {page === 1 && (
        <form onSubmit={handleSignin}>
          <div className="signin__main">
            <h3 className="signin__heading">Sign In</h3>
            <div>
              <FormControl
                title="Email"
                type="email"
                placeholder="Enter Email"
                image={Mail}
                item={email}
                setItem={setEmail}
              />
              <FormControl
                title="Password"
                type="password"
                placeholder="Enter Password"
                image={Lock}
                item={password}
                setItem={setPassword}
              />
              <div className="signin__others">
                {/* <Checkbox
                code={
                  <span className="red left__align">keep me signed in</span>
                }
                setChecked={setChecked}
                checked={checked}
              /> */}
                <p>
                  Don't have an account?{" "}
                  <a className="red" href="/signup">
                    <strong>Sign Up</strong>
                  </a>{" "}
                  &nbsp;&nbsp;or{""}&nbsp;&nbsp;
                  <a className="red" href="#">
                    <strong onClick={() => setOpen(true)}>
                      Forgot Password?
                    </strong>
                  </a>
                </p>
              </div>
            </div>

            <div className="form__forgotpassword">
              <Modal
                open={open}
                onClose={onCloseModal}
                center
                closeIconId="modal__closeicon"
                classNames={{
                  overlay: "customOverlay",
                  modal: "customModal",
                }}
              >
                <div className="forgotpassword__modal">
                  <h3>Reset Password</h3>
                  <form>
                    <FormControl
                      title=""
                      type="email"
                      placeholder="Enter Email"
                      image={Mail}
                      item={resetEmail}
                      setItem={setResetEmail}
                      account
                    />
                  </form>
                  <button onClick={handleForgotPassword}>Reset Password</button>
                </div>
              </Modal>
            </div>
          </div>

          <section className="buttonGroup">
            <button
              disabled={disableNext}
              className={`${disableNext ? "disable" : ""}`}
              type="submit"
            >
              Sign In
            </button>
          </section>
        </form>
      )}

      {page === 2 && (
        <form className="recovery__email">
          <div>
            <h3>Reset Password</h3>
            <p>Please enter the reset token sent to your email</p>

            <FormControl
              title="Token"
              type="name"
              placeholder="Enter reset token"
              image={Company}
              item={resetToken}
              setItem={setResetToken}
              account
            />

            <FormControl
              title="Password"
              type="password"
              placeholder="Enter new password"
              image={Lock}
              item={newPassword}
              setItem={setNewPassword}
              account
            />
            <FormControl
              title="Confirm Password"
              type="password"
              placeholder="Confirm password"
              image={Lock}
              item={confirmPassword}
              setItem={setConfirmPassword}
              className={isDirty ? "isDirty" : ""}
              account
            />
            <button
              onClick={handleResetPassword}
              disabled={disableResetButton}
              className={`${disableResetButton ? "disabled" : ""}`}
            >
              Reset Password
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default withRouter(SignIn);
