import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Pdf from "../Pdf/Pdf";

import "./Acts.scss";

const Acts = ({ acts, history }) => {
  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const [open, setOpen] = useState(false);
  const { success, data } = acts;
  const [url, setUrl] = useState(null);

  const onCloseModal = () => setOpen(false);

  const handleRoute = (sector) => {
    history.push({
      pathname: `/category/${sector}/laws-and-regulations`,
    });
  };

  const handleOpenModal = (contentUrl) => {
    setUrl(contentUrl);
    setOpen(true);
  };

  const handleRecent = async (name, sector, urlLaw) => {
    try {
      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/save-recently-viewed",
        {
          headers: {
            authorization: state.user.token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            lawName: name,
            lawSector: sector,
            url: urlLaw,
          }),
        }
      );
      // console.log(data)
    } catch (e) {}
  };

  return (
    <div className="acts">
      <h3 className="title">Acts / Laws & Regulation</h3>
      <div className="acts__content">
        {success ? (
          <>
            {data.map((act, index) =>
              act ? (
                <div className="acts__details" key={index}>
                  <p
                    onClick={() => {
                      handleOpenModal(act.url);
                      handleRecent(act.name, act.sector, act.url);
                    }}
                  >
                    {act.name}
                  </p>
                  <h5 onClick={() => handleRoute(act.sector)}>See more</h5>
                </div>
              ) : null
            )}
          </>
        ) : (
          <>
            <p>No acts available</p>
          </>
        )}
      </div>
      {<Pdf open={open} onClose={onCloseModal} url={url} />}
    </div>
  );
};

export default withRouter(Acts);
