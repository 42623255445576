import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import * as serviceWorker from "./serviceWorker";

import App from "./App";

import "./index.scss";

// Context
import { StoreProvider } from "./context/context";

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// serviceWorker.register();
