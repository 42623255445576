import React from "react";

import "./Checkbox.scss";

const Checkbox = ({ text, checked, setChecked, code }) => {
  return (
    <>
      <label className="checkbox">
        {code ? code : text}
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <span className="checkmark"></span>
      </label>
    </>
  );
};

export default Checkbox;
