import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";

const Web = (props) => {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path={`${props.match.url}`} component={Home} />
      </Switch>
      <Footer />
    </div>
  );
};

export default withRouter(Web);
