import React, { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";

import Law from "../Law/Law";

const PaginateLaws = ({ data, handlePageClick, type }) => {
  const childRef = useRef();
  const isInitialMount = useRef(true);

  const handleScroll = () => {
    setTimeout(() => {
      childRef.current &&
        childRef.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleScroll();
    }
  }, [data]);

  return (
    <>
      <div className="paginate__laws" ref={childRef}>
        {data && data[type].length > 0 ? (
          <>
            {" "}
            {data[type].map((content) => {
              return <Law key={content.id} {...content} />;
            })}
          </>
        ) : (
          <em> Not available yet!</em>
        )}
      </div>

      <ReactPaginate
        previousLabel={"prev"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={data ? Math.ceil(data.totalElement / 12) : 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination myCustomPagination"}
        activeClassName={"active"}
        activeClassName={"active"}
        disabledClassName={"disClass"}
      />
    </>
  );
};

export default PaginateLaws;
