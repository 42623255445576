import React from "react";
import ReactLoading from "react-loading";

import AccountForm from "../AccountForm/AccountForm";
import UserCard from "../UserCard/UserCard";

import {
  useQuery,
  useQueryCache,
  QueryCache,
  ReactQueryCacheProvider,
} from "react-query";

import "./Account.scss";

// variables
import { url } from "../../utils/appVariables";
import { useEffect } from "react";
import { useContext } from "react";
import { StoreContext } from "../../context/context";

const queryCache = new QueryCache();

const CachedAccount = () => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Account />
    </ReactQueryCacheProvider>
  );
};

const Account = () => {
  const cache = useQueryCache();
  const [intervalMs, setIntervalMs] = React.useState(1000);

  const handleAccountFetch = async () => {
    const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
    const response = await fetch(url + "user-profile", {
      method: "GET",
      headers: {
        authorization: state.user.token,
      },
    });
    const dataArray = await response.json();
    return dataArray.data;
  };

  function useAccountData() {
    return useQuery("dashboardData", handleAccountFetch, {
      refetchInterval: intervalMs,
    });
  }

  const { status, data, error, isFetching } = useAccountData();

  const { setActive } = useContext(StoreContext);
  const user = {
    name: data && data[0].status,
    title: data && data[3].status,
    image: data && data[7].status,
  };

  const accountFormData =
    data && data.filter((entry, index) => index !== data.length - 1);

  useEffect(() => {
    setActive(3);
  }, []);
  return (
    <>
      {status === "loading" || status === "error" ? (
        <ReactLoading type="bubbles" color="#ef3439" height={300} width={200} />
      ) : (
        <div className="account__page">
          <div className="account__usercard">
            <UserCard user={user} />
          </div>
          <div className="account__accountform">
            <AccountForm data={accountFormData} />
          </div>
        </div>
      )}
    </>
  );
};

export default CachedAccount;
