import React, { useContext } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

// style

import "./App.scss";

// pages
import Web from "./pages/Web/Web";
import Signup from "./components/Signup/Signup";
import SignIn from "./components/SignIn/SignIn";
import Category from "./pages/Category/Category";
import Dashboard from "./pages/Dashboard/Dashboard";
import { StoreContext } from "./context/context";

import ContactUs from "./components/ContactUs/ContactUs";

function App() {
  const {
    state: { user },
  } = useContext(StoreContext);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/signin" component={SignIn} />
        <Route path="/content" component={Category} />
        <Route path="/category" component={Category} />
        <Route path="/home" component={Category} />

        <Route
          path="/dashboard"
          render={(props) => {
            return user ? <Dashboard {...props} /> : <Redirect to="/signin" />;
          }}
        />
        <Route exact path={`/contact-us`} component={ContactUs} />
        <Route path="/" component={Web} />
      </Switch>
    </div>
  );
}

export default App;
