import React, { useEffect } from 'react';
import './theme2.css';
import './Calendar.scss';

const Calendar = () => {
  const state = JSON.parse(window.localStorage.getItem('jee__state__client'));

  let scriptLoaded = async () => {
    const element = document.getElementById('caleandar-app');
    const res = await fetch('https://jacksonettiedu.herokuapp.com/all-event', {
      method: 'GET',
      headers: {
        authorization: state.user.token,
      },
    });
    const parsedRes = await res.json();
    const newArray =
      parsedRes.data &&
      parsedRes.data.map((entry, index) => {
        if (!entry.link) {
          return {
            Date: new Date(entry.year, entry.month, entry.day),
            Title: entry.title,
          };
        } else {
          return {
            Date: new Date(entry.year, entry.month, entry.day),
            Title: entry.title,
            Link: entry.link,
          };
        }
      });
    var events = newArray;

    // var events = [
    //   { Date: new Date(2020, 9, 1), Title: "Doctor appointment at 3:25pm." },
    //   {
    //     Date: new Date(2020, 9, 7),
    //     Title: "New Garfield movie comes out!",
    //     Link: "https://garfield.com",
    //   },
    //   {
    //     Date: new Date(2016, 6, 11),
    //     Title: "25 year anniversary",
    //     Link: "https://www.google.com.au/#q=anniversary+gifts",
    //   },
    // ];

    var settings = {
      Color: '#999', //(string - color) font color of whole calendar.
      LinkColor: '#333', //(string - color) font color of event titles.
      NavShow: true, //(bool) show navigation arrows.
      NavVertical: false, //(bool) show previous and coming months.
      NavLocation: '#foo', //(string - element) where to display navigation, if not in default position.
      DateTimeShow: true, //(bool) show current date.
      DateTimeFormat: 'mmm, yyyy', //(string - dateformat) format previously mentioned date is shown in.
      DatetimeLocation: '', //(string - element) where to display previously mentioned date, if not in default position.
      EventClick: '', //(function) a function that should instantiate on the click of any event. parameters passed in via data link attribute.
      EventTargetWholeDay: false, //(bool) clicking on the whole date will trigger event action, as opposed to just clicking on the title.
      DisabledDays: [], //(array of numbers) days of the week to be slightly transparent. ie: [1,6] to fade Sunday and Saturday.
      ModelChange: [], //(array of objects) new data object to pass into calendar (serving suggestion: passing through only the currently selected month's events if working with large dataset.
    };
    window.caleandar(element, events, settings);
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/static/libs/js/caleandar.js';
    script.async = true;
    script.onload = () => scriptLoaded();
    document.body.appendChild(script);
  }, []);

  return (
    <div className="calendar">
      <h3>JEE Events</h3>
      <div id="caleandar-app"></div>
    </div>
  );
};

export default Calendar;
