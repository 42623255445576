import React from "react";
import {withRouter} from "react-router-dom";

import "./Podcast.scss";

import PodcastImg from '../../assets/img/podcastImg.jpg';

const Podcast = (props)=>{

return  <div className="podcast__image">
<img src={PodcastImg} alt="coming soon"/>
<h1> Expect our podcasts soon... </h1>

<span onClick={props.history.goBack}>Go Back</span>
</div>
}

export default withRouter(Podcast)