import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <section className="categories">
        <div className="category">
          <span>Corporate</span>
          <span>About Us</span>
          <span>Find a Lawyer</span>
          <span>Services</span>
          <span>Client Login</span>
        </div>
        <div className="category">
          <span>The Practice</span>
          <span>Practice Areas</span>
          <span>Sectors</span>
          <span>Our Awards</span>
        </div>
        <div className="category">
          <span>News & Resources</span>
          <span>Articles & Publications</span>
          <span>Gallery</span>
          <span>Thought Leadership & Newsletters</span>
          <span>Careers</span>
        </div>
        <div className="category">
          <span>Contact</span>
          <span>
            RCO Court, 3-5 Sinari Daranijo Street, Off Ajose Adeogun, Victoria
            Island, Lagos, Nigeria.
          </span>
          <span>Tel: +234 1 4626841/3</span>
          <span style={{ color: 'white' }}>
            jacksonettiedu@jacksonettiandedu.com
          </span>
          <span>Lagos . Abuja . Ikeja . Ghana . Cameroon</span>
        </div>
      </section>
      <section className="others">
        <span>
          COPYRIGHT © {new Date().getFullYear()}. JACKSON, ETTI & EDU | PRIVACY
          POLICY{' '}
        </span>
        <div className="icons__list">
          <span
            className="iconify"
            data-icon="ant-design:instagram-outlined"
            data-inline="false"
          ></span>
          <span
            className="iconify"
            data-icon="fa-brands:linkedin"
            data-inline="false"
          ></span>
          <span
            className="iconify"
            data-icon="eva:twitter-fill"
            data-inline="false"
          ></span>
        </div>
      </section>
    </div>
  );
};

export default Footer;
