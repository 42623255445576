import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { withRouter } from "react-router-dom";
// import Img from "react-optimized-image";

import "./DesktopSidebar.scss";

import Logo from "../../assets/img/jee-logo.png";
import Home from "../../assets/img/8234192981537184103.svg";
import Blog from "../../assets/img/5125412361595601822.svg";
import Account from "../../assets/img/profile (1).svg";
import Preferences from "../../assets/img/choices.svg";
import Contact from "../../assets/img/12454851961582823590.svg";
import Exit from "../../assets/img/exit.svg";

import { StoreContext } from "../../context/context";

import Feedback from "../Feedback/Feedback";

const DesktopSidebar = (props) => {
  const {
    setUser,
    state: { active },
  } = useContext(StoreContext);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setOpen(false);
  };

  const mainTabs = [
    {
      text: "Home",
      image: Home,
      url: "/dashboard",
    },
    {
      text: "Sectors",
      image: Blog,
      url: "/dashboard/sectors",
    },
    {
      text: "Preferences",
      image: Preferences,
      url: "/dashboard/preferences",
    },
    {
      text: "Account",
      image: Account,
      url: "/dashboard/account",
    },
  ];

  const footerTabs = [
    {
      text: "Contact Us",
      image: Contact,
      url: "/contact-us",
    },
    {
      text: "Logout",
      image: Exit,
      url: "/",
    },
  ];

  const handleClick = (route) => {
    props.history.push(`${route}`);
  };

  const handleFeedbackClose = () => {
    setOpen(false);
    setUser(null);
    handleClick("/");
  };

  const handleLogOut = () => {
    onOpenModal();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleFeedbackClose}
        center
        closeIconId="modal__closeicon"
        classNames={{
          overlay: "feedbackOverlay",
          modal: "feedbackModal",
        }}
      >
        <Feedback closeHandler={handleFeedbackClose}></Feedback>
      </Modal>
      <div className="dsb">
        <img
          src={Logo}
          alt="logo"
          className="dsb__logo"
          onClick={() => {
            handleClick("/");
          }}
        />
        <section className="dsb__options">
          {mainTabs.map((entry, index) => {
            return (
              <div
                className={`dsb__option ${
                  active === index ? "activeSidebar" : ""
                }`}
                onClick={() => {
                  handleClick(entry.url);
                }}
                key={index}
              >
                <div>
                  <img alt="icon" className="image__icon" src={entry.image} />{" "}
                  {entry.text}
                </div>
              </div>
            );
          })}
        </section>
        <section className="dsb__footer">
          {footerTabs.map((entry, index) => {
            return (
              <div
                className={`footer__item ${
                  entry.text === "Logout" ? "logout" : ""
                } `}
                key={index}
                onClick={() => {
                  if (entry.text === "Logout") {
                    return handleLogOut();
                  }
                  handleClick(entry.url);
                }}
              >
                {" "}
                <div>
                  <img alt="icon" className="image__icon" src={entry.image} />
                  {entry.text}
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default withRouter(DesktopSidebar);
