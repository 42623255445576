import React, { useState } from "react";
import "./Feedback.scss";
const Feedback = ({ closeHandler }) => {
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const state = JSON.parse(
        window.localStorage.getItem("jee__state__client")
      );

      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/save-feedback",
        {
          headers: {
            authorization: state.user.token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            feedback,
          }),
        }
      );

      const parsed = await data.json();
      // console.log(parsed);
      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }
      setLoading(false);
      closeHandler();
    } catch (e) {
      setLoading(false);
      closeHandler();
    }
  };

  return (
    <div className="feedback">
      <div className="feedback__header">
        <span>Send us your feedback!</span>
        <span className="cancel" onClick={() => closeHandler(false)}>
          &#10006;&#xfe0e;
        </span>
      </div>
      <div className="feedback__content">
        <form onSubmit={handleSubmit}>
          <p>
            We would be pleased to receive any suggestions to make your
            experience better.
          </p>
          <textarea
            placeholder="Your suggestion"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
        </form>
      </div>
      <footer>
        <button
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          className={`${loading ? "disabled" : ""}`}
        >
          {loading ? "Sending..." : "Submit"}
        </button>
      </footer>
    </div>
  );
};

export default Feedback;
