import { baseURL } from "./appVariables";

export const getNewsData = (page, type, sector, size) => {
  // const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  return new Promise(async (resolve, reject) => {
    try {
      const news = await fetch(`${baseURL}/${type}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // authorization: state.user.token,
        },
        body: JSON.stringify({
          sector,
          page,
          size,
        }),
      });
      const value = await news.json();
      resolve(value);
    } catch (e) {
      reject(e);
    }
  });
};

export const getWebinarsData = (page, size) => {
  // const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  return new Promise(async (resolve, reject) => {
    try {
      const webinars = await fetch(`${baseURL}/webinar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // authorization: state.user.token,
        },
        body: JSON.stringify({
          page,
          size,
        }),
      });
      const value = await webinars.json();
      resolve(value);
    } catch (e) {
      reject(e);
    }
  });
};
