import React from "react";
import Checkbox from "../Checkbox/Checkbox";

import "./Terms.scss";

const Terms = ({ agree, setAgree }) => {
  return (
    <div className="terms">
      <h3>Terms & Conditions</h3>
      <p>Please read and understand before signing up</p>
      <Checkbox
        code={
          <span>
            Yes I understand and agree to Jackson, Etti & Edu{" "}
            <span className="highlight">Terms of Service</span>, including the{" "}
            <span className="highlight">User Agreement</span> and{" "}
            <span className="highlight">Privacy Policy</span>
          </span>
        }
        checked={agree}
        setChecked={setAgree}
      />
    </div>
  );
};

export default Terms;
