import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import Img from "react-optimized-image";

import { nameArray, slugArray } from '../../utils/appVariables';

import Arrow from '../../assets/img/double-left-arrows-symbol.svg';

import './Blogs.scss';

import { StoreContext } from '../../context/context';

const Blogs = (props) => {
  const handleClick = (index) => {
    props.history.push(`/category/${slugArray[index]}/blog`);
  };

  const { setActive } = useContext(StoreContext);

  useEffect(() => {
    setActive(1);
  }, []);

  return (
    <div className="sectors__container">
      <div className="sectors__headers">
        <h3>Blog</h3>
        <p>
          We are focused on key sectors and have developed a reputation for
          understanding your business and for delivering innovative services
          that anticipate your needs
        </p>
      </div>
      <div className="sectors__gallery">
        {nameArray.map((entry, index) => (
          <div key={index} className={`sectors__sector ${slugArray[index]}`}>
            <div className="sector__main" onClick={() => handleClick(index)}>
              <span>{entry}</span>
              <img src={Arrow} alt="view" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(Blogs);
