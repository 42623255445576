import React from "react";

import "./HomeSector.scss";

const HomeSector = ({ data, onClick }) => {
  const { subHeading, mainHeading, desc, bgClass } = data;
  return (
    <div className={`homesector  `} onClick={onClick}>
      <div className={`homesector__main ${bgClass}`}>
        <h4>{subHeading}</h4>
        <div>
          <h2>{mainHeading}</h2>
          <p>{desc}</p>
        </div>
      </div>
      <div className="arrowDiv"></div>
    </div>
  );
};

export default HomeSector;
