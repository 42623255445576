import React, { useState } from "react";
import FormControl from "../FormControl/FormControl";
import LoadingBar from "react-top-loading-bar";
import { Modal } from "react-responsive-modal";
import swal from "sweetalert";
// import Img from "react-optimized-image";

import Edit from "../../assets/img/edit.svg";

import "./AccountForm.scss";
import { url } from "../../utils/appVariables";
import { useRef } from "react";

const AccountForm = ({ data }) => {
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setOpen(false);
  };

  const disableResetButton =
    !oldPassword || !newPassword || confirmPassword !== newPassword;

  const isDirty = confirmPassword !== "" && confirmPassword !== newPassword;

  const ref = useRef(null);

  const handleNewPassword = (index, value) => {
    setNewPassword(value);
  };
  const handleOldPassword = (index, value) => {
    setOldPassword(value);
  };
  const handleConfirmPassword = (index, value) => {
    setConfirmPassword(value);
  };

  const handleResetPassword = async () => {
    ref.current.continuousStart();
    try {
      const state = JSON.parse(
        window.localStorage.getItem("jee__state__client")
      );

      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/update-password",
        {
          headers: {
            authorization: state.user.token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            oldPassword,
            newPassword,
          }),
        }
      );
      const parsed = await data.json();
      // console.log(parsed);
      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }
      ref.current.complete();
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setOpen(false);
      swal("Success!", `Password change successful`, "success");
    } catch (e) {
      ref.current.complete();
      swal("Oops!", `${e.customMessage || "Something went wrong!"}`, "warning");
      setOpen(false);
    }
  };

  const convertData = (dataArray) => {
    const mapArray = [
      "fullName",
      "email",
      "companyName",
      "jobTitle",
      "country",
      "phoneNumber",
      "gender",
    ];
    const newData = dataArray.reduce((acc, curr, index) => {
      return {
        ...acc,
        [mapArray[index]]: curr.status,
      };
    }, {});

    // Remove email

    delete newData.email;
    // console.log(JSON.stringify(newData));
    return newData;
  };

  const handleSave = async () => {
    ref.current.continuousStart();
    try {
      const state = JSON.parse(
        window.localStorage.getItem("jee__state__client")
      );
      const data = await fetch(url + "save-user-profile", {
        method: "POST",
        headers: {
          authorization: state.user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...convertData(stateArray),
        }),
      });
      const use = await data.json();

      // console.log(use);

      ref.current.complete();
    } catch (e) {
      ref.current.complete();
      // console.log(e);
      swal("Oops!", `something went wrong, please try again`, "warning");
    }
  };

  const [stateArray, setStateArray] = useState(data);

  const handleChange = (index, value) => {
    const sameArray = [...stateArray];
    sameArray[index] = {
      ...sameArray[index],
      status: value,
    };
    setStateArray(sameArray);
  };

  return (
    <div className="accountform">
      <LoadingBar color="#f11946" ref={ref} />
      <div className="accountform__header">
        <div className="accountform__header--main">
          <h3 onClick={() => setDisabled(false)}>Click to edit</h3>{" "}
          <img src={Edit} alt="Edit" onClick={() => setDisabled(false)} />
        </div>

        <div className="form__changepassword">
          <span onClick={onOpenModal}>Change password?</span>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            closeIconId="modal__closeicon"
            classNames={{
              overlay: "customOverlay",
              modal: "customModal",
            }}
          >
            {" "}
            <div className="changepassword__modal">
              <h3>Change Password</h3>
              <form>
                <FormControl
                  title="Old Password"
                  item={oldPassword}
                  type="password"
                  index={1}
                  handleChange={handleOldPassword}
                />
                <FormControl
                  title="New Password"
                  item={newPassword}
                  type="password"
                  index={2}
                  handleChange={handleNewPassword}
                />
                <FormControl
                  title="Confirm Password"
                  item={confirmPassword}
                  type="password"
                  index={3}
                  handleChange={handleConfirmPassword}
                  className={isDirty ? "isDirty" : ""}
                />
              </form>
              <button
                onClick={handleResetPassword}
                disabled={disableResetButton}
                className={`${disableResetButton ? "disabled" : ""}`}
              >
                Change
              </button>
            </div>
          </Modal>
        </div>
      </div>

      <form className="account__segments">
        <div>
          {stateArray.map((entry, index) => {
            let { title, status, type } = entry;
            if (index > 4) return;
            return (
              <div className="form__control" key={index}>
                <FormControl
                  title={title}
                  item={status}
                  type={type}
                  index={index}
                  handleChange={handleChange}
                  account={true}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>

        <div>
          {stateArray.map((entry, index) => {
            const { title, status, type } = entry;
            if (index < 5) return;
            return (
              <div className="form__control" key={index}>
                <FormControl
                  title={title}
                  item={status}
                  type={type}
                  index={index}
                  handleChange={handleChange}
                  notRequired={true}
                  account={true}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
      </form>
      <div className="accountform__footer">
        <button
          onClick={() => {
            setDisabled(true);
            setStateArray(data);
          }}
        >
          Cancel
        </button>{" "}
        <button
          onClick={handleSave}
          disabled={disabled}
          className={`${disabled ? "disabled" : ""}`}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default AccountForm;
