import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';

import FullCard from '../FullCard/FullCard';

// Images
import RealEstate from '../../assets/img/energy-newsletter.png';
import FastConsumer from '../../assets/img/fast-moving-newsletter.jpg';
import Finance from '../../assets/img/finance-newsletter.png';
import Health from '../../assets/img/health-newsletter.png';
import Technology from '../../assets/img/tech-newsletter.png';
// import PublicSector from "../../assets/img/pexels-skitterphoto-735795.png";
import Search from '../../assets/img/3028692271535694869.svg';

// variables

import { slugArray, nameArray, url } from '../../utils/appVariables';
import { getNewsData } from '../../utils/helpers';

import './NewsLetter.scss';
import PaginateContainer from '../PaginateContainer/PaginateContainer';

const NewsLetter = ({ history, home }) => {
  let [data, setData] = useState();
  const [searchText, setSearchText] = useState('');
  const [hasSearched, setHasSearched] = useState(false);

  const state = JSON.parse(window.localStorage.getItem('jee__state__client'));
  const loggedIn = !!(state && state.user);

  const imageArray = [RealEstate, FastConsumer, Finance, Health, Technology];

  const { category } = useParams();
  const index = slugArray.indexOf(category);

  const getData = async () => {
    setData(null);
    try {
      const content = await getNewsData(0, 'newsletter', category, 12);
      // console.log(content);
      setData(content.data);
      setHasSearched(false);
    } catch (e) {
      swal('Oops!', 'Something went wrong!', 'error');
      setHasSearched(false);
    }
  };

  const handleSearch = async (page, omit) => {
    setHasSearched(true);
    omit && setData(null);
    try {
      const state = JSON.parse(
        window.localStorage.getItem('jee__state__client')
      );

      const data = await fetch('https://jacksonettiedu.herokuapp.com/search', {
        headers: {
          // authorization: state.user.token,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          title: searchText,
          sector: category,
          type: 'newsletter',
          page: page,
          size: 12,
        }),
      });
      const parsed = await data.json();
      if (!parsed.success) {
        const error = new Error('');
        error.customMessage = parsed.message;
        throw error;
      }

      setData(parsed.data);
    } catch (e) {
      getData();
      swal('Oops!', `${e.customMessage || 'Something went wrong!'}`, 'warning');
    }
  };

  const handlePageClick = async (obj) => {
    if (data.search) {
      handleSearch(obj.selected, false);
    } else {
      try {
        const content = await getNewsData(
          obj.selected,
          'newsletter',
          category,
          12
        );
        setData(content.data);
        setHasSearched(false);
      } catch (e) {
        setHasSearched(false);
        swal('Oops!', 'Something went wrong!', 'error');
      }
    }
  };

  const handleSlugRoute = () => {
    if (loggedIn) {
      history.push(`/dashboard/sectors/${slugArray[index]}`);
    } else {
      history.push({
        pathname: '/',
        state: {
          sector: slugArray[index],
          page: 2,
          sectorView: true,
          sectorName: nameArray[index],
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [category]);

  useEffect(() => {
    if (hasSearched && searchText === '') {
      getData();
    }
  }, [searchText]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="newsletter">
      <img alt="header_background" src={imageArray[index]} />
      <div className="newsletter__searchrow">
        <span className="detail">
          <strong
            style={{
              cursor: 'pointer',
            }}
            className="strong__slug"
            onClick={handleSlugRoute}
          >
            {nameArray[index]}
          </strong>{' '}
          \ <span>Updates</span>
        </span>{' '}
        <span className="searchbox">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(0, true);
            }}
          >
            <input
              type="text"
              placeholder="Search Newletter"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <img
              src={Search}
              alt="search"
              onClick={() => handleSearch(0, true)}
            />
          </form>
        </span>
      </div>

      {data && data['newsLetters'][0] && (
        <FullCard data={data['newsLetters'][0]} contentType="newsletter" />
      )}

      {data ? (
        <PaginateContainer
          data={data}
          handlePageClick={handlePageClick}
          type="newsLetters"
        />
      ) : (
        <ReactLoading type="bubbles" color="#ef3439" height={300} width={200} />
      )}
    </div>
  );
};

export default withRouter(NewsLetter);
