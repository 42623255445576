import React, { useState, useContext, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import ReactLoading from "react-loading";

import "./Preferences.scss";

import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

// Context
import { StoreContext } from "../../context/context";
import { nameArray, slugArray, mapObject } from "../../utils/appVariables";

const Preferences = () => {
  const ref = useRef();
  const { setActive } = useContext(StoreContext);
  const [stateArray, setStateArray] = useState(null);
  const [bright, setBright] = useState(false);

  const handleStateChange = (index) => {
    !bright && setBright(true);
    const newValue = !stateArray[index].status;
    const sameArray = [...stateArray];
    sameArray[index] = { title: sameArray[index].title, status: newValue };
    setStateArray(sameArray);
  };

  const getPreferences = async () => {
    const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
    const data = await fetch(
      "https://jacksonettiedu.herokuapp.com/user-preferences",
      {
        headers: {
          authorization: state.user.token,
        },
        method: "GET",
      }
    );

    const parseData = await data.json();
    const mainData = parseData.data;

    const result = nameArray.reduce((acc, curr, index) => {
      return [
        ...acc,
        {
          title: curr,
          status: mainData.includes(slugArray[index]),
        },
      ];
    }, []);
    // console.log(result);
    setStateArray(result);
  };

  const setPreferences = async () => {
    setBright(false);
    ref.current.continuousStart();
    const state = JSON.parse(window.localStorage.getItem("jee__state__client"));

    const selectedSectors = stateArray.reduce((newArr, curr, index) => {
      if (!curr.status) return newArr;
      return [...newArr, slugArray[index]];
    }, []);

    const numbers = [
      "sectorOne",
      "sectorTwo",
      "sectorThree",
      "sectorFour",
      "sectorFive",
      "sectorSix",
    ];

    const secObj = selectedSectors.reduce((newObj, curr, index) => {
      return { ...newObj, [numbers[index]]: curr };
    }, {});

    const registeredSectors = {
      sectorOne: null,
      sectorTwo: null,
      sectorThree: null,
      sectorFour: null,
      sectorFive: null,
      sectorSix: null,
      ...secObj,
    };

    const data = await fetch(
      "https://jacksonettiedu.herokuapp.com/save-user-preferences",
      {
        headers: {
          authorization: state.user.token,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(registeredSectors),
      }
    );

    const parsed = await data.json();
    ref.current.complete();
  };

  useEffect(() => {
    setActive(2);
    getPreferences();
  }, []);

  return (
    <div className="preferences">
      {stateArray ? (
        <>
          <LoadingBar color="#f11946" ref={ref} />
          <h3>Preferences</h3>
          <p>
            Choose or remove sector preferences that you would like to get
            updates from.
          </p>
          <div className="preferences__options">
            {stateArray.map((entry, index) => (
              <div key={index} className="preferences__option">
                <span>{entry.title}</span>
                <ToggleSwitch
                  checked={entry.status}
                  handleChange={handleStateChange}
                  index={index}
                />
              </div>
            ))}
            <div className="preferences__footer">
              <button
                onClick={setPreferences}
                disabled={!bright}
                className={`${bright ? "" : "disabled"}`}
              >
                Update
              </button>
            </div>
          </div>{" "}
        </>
      ) : (
        <ReactLoading type="bubbles" color="#ef3439" height={300} width={200} />
      )}
    </div>
  );
};

export default Preferences;
