import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import swal from 'sweetalert';
import LoadingBar from 'react-top-loading-bar';

import AboutYou from '../AboutYou/AboutYou';
import Tabs from '../Tabs/Tabs';
import Logo from '../../assets/img/jee-white-logo.png';
import Pref from '../Pref/Pref';
import Terms from '../Terms/Terms';
import CustomError from '../Error/Error';

import './Signup.scss';

const Signup = (props) => {
  const [tab, setTab] = useState(1);

  const ref = useRef(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [cname, setCname] = useState('');

  const [real, setReal] = useState(false);
  const [fast, setFast] = useState(false);
  const [finance, setFinance] = useState(false);
  const [health, setHealth] = useState(false);
  const [tech, setTech] = useState(false);
  const [agree, setAgree] = useState(false);

  const [isRegistering, setIsRegistering] = useState(false);

  const handleClick = (route) => {
    props.history.push(`${route}`);
  };

  const aboutProps = {
    name,
    setName,
    email,
    setEmail,
    cname,
    setCname,
  };

  const prefProps = {
    real,
    fast,
    finance,
    health,
    tech,
    setReal,
    setFast,
    setFinance,
    setHealth,
    setTech,
  };

  const termsProps = {
    agree,
    setAgree,
  };

  const showBack = tab === 1 ? false : true;
  const showNext = tab === 3 ? false : true;

  const disable2 = tab === 2 && (!name || !email || !cname);
  const disable1 = tab === 1 && !real && !fast && !finance && !health && !tech;
  let disable3 = tab === 3 && !agree;

  let disableNext = disable1 || disable2 || disable3;

  const add = () => {
    if (tab !== 3) {
      setTab(tab + 1);
    }
  };

  const subtract = () => {
    setTab(tab - 1);
  };

  const handleRegistration = async () => {
    if (isRegistering) {
      return;
    }

    setIsRegistering(true);
    ref.current.continuousStart();

    try {
      const sectors = [real, fast, finance, health, tech];
      const sectorsNames = [
        'real-estate-and-infrastructure',
        'fast-moving-consumer-goods',
        'financial-service',
        'health-and-pharmaceuticals',
        'technology-media-entertainment',
      ];

      const selectedSectors = sectors.reduce((newArr, curr, index) => {
        if (!curr) return newArr;
        return [...newArr, sectorsNames[index]];
      }, []);

      const numbers = [
        'sectorOne',
        'sectorTwo',
        'sectorThree',
        'sectorFour',
        'sectorFive',
        'sectorSix',
      ];

      const secObj = selectedSectors.reduce((newObj, curr, index) => {
        return { ...newObj, [numbers[index]]: curr };
      }, {});

      const registerSectors = {
        sectorOne: null,
        sectorTwo: null,
        sectorThree: null,
        sectorFour: null,
        sectorFive: null,
        sectorSix: null,
        ...secObj,
      };

      const register = await fetch(
        'https://jacksonettiedu.herokuapp.com/register',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fullName: name,
            email,
            companyName: cname,
            ...registerSectors,
          }),
        }
      );

      const response = await register.json();

      if (!response.success) {
        throw new CustomError(response.message);
      }

      ref.current.complete();

      const successful = await swal({
        title: 'Successful Registration',
        text:
          "We'd confirm your registeration, and send you an email afterwards.",
        icon: 'success',
      });
      if (successful) {
        props.history.push('/');
      }
    } catch (e) {
      ref.current.complete();
      swal(
        'Oops!',
        `${e.isCustomError ? e.message : 'Something went wrong'}`,
        'error'
      );
    }
    setIsRegistering(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showNext) return add();
    handleRegistration();
  };

  return (
    <div className="signup">
      <LoadingBar color="#f11946" ref={ref} />
      <div className="signup__logo">
        <img alt="logo" src={Logo} onClick={() => handleClick('/')} />
      </div>
      <form onSubmit={handleSubmit}>
        <Tabs tab={tab} />
        {tab === 1 && <Pref {...prefProps} />}
        {tab === 2 && <AboutYou {...aboutProps} />}
        {tab === 3 && <Terms {...termsProps} />}

        <section className="buttonGroup">
          <span
            className={`${showBack ? '' : 'noShow'}`}
            onClick={() => subtract()}
          >
            <i>&#x3c;</i> Back
          </span>

          <button
            disabled={disableNext}
            className={`${disableNext ? 'disable' : ''}`}
            type="submit"
          >
            {showNext ? 'Next' : 'Register'}
          </button>
        </section>
      </form>
    </div>
  );
};

export default withRouter(Signup);
