import React from "react";
import Checkbox from "../Checkbox/Checkbox";

import "./Pref.scss";

const Pref = ({
  real,
  fast,
  finance,
  health,
  tech,
  setReal,
  setFast,
  setFinance,
  setHealth,
  setTech,
}) => {
  return (
    <div className="pref">
      <h3>Preference</h3>
      <p>
        What sectors are you interested in and would love to get the latest
        updates on!
      </p>
      <Checkbox
        text="Energy & Infrastrucuture"
        checked={real}
        setChecked={setReal}
      />
      <Checkbox
        text="Fast Moving Consumer Goods"
        checked={fast}
        setChecked={setFast}
      />
      <Checkbox
        text="Financial Services"
        checked={finance}
        setChecked={setFinance}
      />
      <Checkbox
        text="Health & Pharmaceuticals"
        checked={health}
        setChecked={setHealth}
      />
      <Checkbox
        text="Technology, Media & Entertainment"
        checked={tech}
        setChecked={setTech}
      />
    </div>
  );
};

export default Pref;
