import React from "react";
import { Link } from "react-router-dom";
import reactElementToJSXString from "react-element-to-jsx-string";
// import Truncate from "react-truncate-html";
import Truncate from "react-truncate";

import "./NewsCard.scss";

const NewsCard = (props) => {
  const {
    id,
    summary,
    topic,
    imageUrl,
    pdfUrl,
    details,
    textDate,
    sector,
  } = props;

  const getReducedWords = (sentence) => {
    const strA = sentence.split(" ");
    const shortendArray = strA.reduce((acc, curr, index) => {
      if (index < 40) {
        return [...acc, curr];
      } else if (index === 40) {
        return [...acc, "..."];
      } else {
        return acc;
      }
    }, []);
    return shortendArray.join(" ");
  };

  return (
    <Link
      to={{
        pathname: `/content/${sector}/${id}`,
        state: {
          data: props,
        },
      }}
    >
      <div className="newscard">
        <div className="newscard__container">
          <div className="newscard__img__container">
            <img src={imageUrl} alt="newsletter" />
          </div>
          <div className="newscard__detail">
            <div className="newscard__demo">
              <h4>{topic}</h4>
              <span>{textDate}</span>
            </div>
            <p>{getReducedWords(summary)}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
