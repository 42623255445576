import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import ReactLoading from "react-loading";
import swal from "sweetalert";

import Search from "../../assets/img/3028692271535694869.svg";

import { slugArray, nameArray } from "../../utils/appVariables";
import { getWebinarsData } from "../../utils/helpers";

import "./Webinars.scss";

import Video from "../../assets/videos/webinar-vid.mp4";
import PaginateWebinars from "../PaginateWebinars";

const Thought = ({ history }) => {
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));
  const loggedIn = !!(state && state.user);

  const { category } = useParams();
  const index = slugArray.indexOf(category);

  const getData = async () => {
    setData(null);
    try {
      const content = await getWebinarsData(0, 12);
      // console.log(content);
      setData(content.data);
      setHasSearched(false);
    } catch (e) {
      swal("Oops!", "Something went wrong!", "error");
      setHasSearched(false);
    }
  };

  const handleSearch = async (page, omit) => {
    setHasSearched(true);
    omit && setData(null);
    try {
      // const state = JSON.parse(
      //   window.localStorage.getItem("jee__state__client")
      // );

      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/search-webinar",
        {
          headers: {
            // authorization: state.user.token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            title: searchText,
            page: page,
            size: 12,
          }),
        }
      );
      const parsed = await data.json();

      if (!parsed.success) {
        const error = new Error("");
        error.customMessage = parsed.message;
        throw error;
      }

      setData(parsed.data);
    } catch (e) {
      getData();
      swal("Oops!", `${e.customMessage || "Something went wrong!"}`, "warning");
    }
  };

  const handlePageClick = async (obj) => {
    if (data.search) {
      handleSearch(obj.selected, false);
    } else {
      try {
        const content = await getWebinarsData(obj.selected, 12);
        setData(content.data);
        setHasSearched(false);
      } catch (e) {
        setHasSearched(false);
        swal("Oops!", "Something went wrong!", "error");
      }
    }
  };

  const handleSlugRoute = () => {
    if (loggedIn) {
      history.push(`/dashboard/sectors/${slugArray[index]}`);
    } else {
      history.push({
        pathname: "/",
        state: {
          sector: slugArray[index],
          page: 2,
          sectorView: true,
          sectorName: nameArray[index],
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [category]);

  useEffect(() => {
    if (hasSearched && searchText === "") {
      getData();
    }
  }, [searchText]);

  // let [data, setData] = useState();

  // const handlePageClick = async (obj) => {
  //   try {
  //     const content = await getNewsData(
  //       obj.selected,
  //       "thoughtleadership",
  //       category,
  //       12
  //     );
  //     setData(content.data);
  //   } catch (e) {
  //     swal("Oops!", "Something went wrong!", "error");
  //   }
  // };

  // const { category } = useParams();
  // const index = slugArray.indexOf(category);

  // useEffect(() => {
  //   (async function getData() {
  //     try {
  //       const content = await getNewsData(0, "thoughtleadership", category, 12);
  //       setData(content.data);
  //     } catch (e) {
  //       swal("Oops!", "Something went wrong!", "error");
  //     }
  //   })();
  // }, [category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="webinars__header">
        <video autoPlay>
          <source src={Video} type="video/mp4" />
        </video>
        <div className="webinars__header__content">
          <h3>Webinars</h3>
          <p>Our webinars are available at your convenience.</p>
        </div>
      </div>

      <div className="newsletter__searchrow">
        <span className="detail">
          <strong
            style={{ cursor: "pointer" }}
            // onClick={handleSlugRoute}
            className="strong__slug"
          >
            {/* {nameArray[index]} */}
            Webinars
          </strong>{" "}
          {/* \ <span></span> */}
        </span>{" "}
        <span className="searchbox">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(0, true);
            }}
          >
            <input
              type="text"
              placeholder="Search Webinars"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <img
              src={Search}
              alt="search"
              onClick={() => handleSearch(0, true)}
            />
          </form>
        </span>
      </div>

      {data ? (
        <PaginateWebinars
          data={data}
          handlePageClick={handlePageClick}
          type="webinars"
        />
      ) : (
        <ReactLoading type="bubbles" color="#ef3439" height={150} width={200} />
      )}
    </div>
  );
};

export default withRouter(Thought);
