import React, { useState } from "react";
// import Img from "react-optimized-image";

import "./Law.scss";

import PDF from "../../assets/img/pdf-file.svg";
import Pdf from "../Pdf/Pdf";

const Law = (props) => {
  const { url, name, id, sector } = props;
  const [open, setOpen] = useState(false);

  const handleDownload = () => {
    let a = document.createElement("a");
    a.href = url;
    a.download = `${id}`;
    a.target = "_blank";
    a.click();
  };

  const state = JSON.parse(window.localStorage.getItem("jee__state__client"));

  const handleRecent = async () => {
    try {
      const data = await fetch(
        "https://jacksonettiedu.herokuapp.com/save-recently-viewed",
        {
          headers: {
            authorization: state.user.token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            lawName: name,
            lawSector: sector,
            url,
          }),
        }
      );
    } catch (e) {}
  };

  const handleDownloadAndRecent = () => {
    handleDownload();
    handleRecent();
  };

  const handleLearnAndRecent = () => {
    setOpen(true);
    handleRecent();
  };

  const onCloseModal = () => setOpen(false);

  return (
    <div className="law__component">
      <h3>{name}</h3>
      <div className="law__options">
        <span onClick={handleLearnAndRecent}>Learn More</span>
        {/* <div onClick={handleDownloadAndRecent}>
          <img src={PDF} alt="pdf" />
          <span>Download</span>
        </div> */}
      </div>
      <Pdf open={open} onClose={onCloseModal} url={url} />
    </div>
  );
};

export default Law;
