import React from "react";
import Arr from "../../assets/img/white-arrow.png";
// import Img from "react-optimized-image";

import "./AnimatedNews.scss";

const AnimatedNews = ({
  title,
  detail,
  type,
  urlSlug,
  onClick,
  small,
  className,
}) => {
  return (
    <div
      className={`animatednews ${className} ${type} ${
        small ? "small__news" : ""
      } `}
      onClick={onClick}
    >
      <div className="animatednews__content">
        <div className="animatednews__animate">
          <div className="animatednews__heading">
            <div>{title}</div>
            <div className="animated__mobilearrow">
              <img src={Arr} alt="arrow" />
            </div>
          </div>

          <p className="animatednews__details">
            {detail}

            <img src={Arr} alt="arrow" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnimatedNews;
