import React from 'react';
import { withRouter } from 'react-router-dom';
import AnimatedNews from '../AnimatedNews/AnimatedNews';

import './ImageGallery.scss';

const ImageGallery = ({ small, slug, history, home }) => {
  const state = JSON.parse(window.localStorage.getItem('jee__state__client'));
  const loggedIn = !!(state && state.user);

  const dataRegular = [
    {
      type: 'laws__bg',
      urlSlug: 'laws-and-regulations',
      title: 'Laws and Regulations',
      detail:
        'Remain at the forefront of key regulatory issues that guide your business.',
    },
    {
      type: 'news__bg',
      urlSlug: 'newsletter',
      title: 'Updates',
      detail:
        'Keeping you informed on latest legal trends and developments that could impact your sector.',
    },
    {
      type: 'thought__bg',
      urlSlug: 'thought',
      title: 'Thought Leadership & Newsletter',
      detail:
        'Our perspectives on key topics, mitigating risk and areas of opportunity for your business.',
    },
    {
      type: 'webinars__bg',
      urlSlug: 'webinars',
      title: 'Webinars',
      detail: 'Our webinars are available at your convenience.',
      className: 'webinar',
    },

    // {
    //   type: "podcast",
    //   urlSlug: "podcast",
    //   title: "Podcasts",
    //   detail:
    //     "Gain access to a wide array of newsletters and updates. We regularly issue announcements covering important legal issues and news from the firm.",
    // },
    // {
    //   type: "blogs",
    //   urlSlug: "blog",
    //   title: "Blogs",
    //   detail:
    //     "Gain access to a wide array of newsletters and updates. We regularly issue announcements covering important legal issues and news from the firm.",
    // },
  ];

  const dataHome = [
    {
      type: 'news__bg',
      urlSlug: 'newsletter',
      title: 'Updates',
      detail:
        'Keeping you informed on latest legal trends and developments that could impact your sector.',
    },
    {
      type: 'thought__bg',
      urlSlug: 'thought',
      title: 'Thought Leadership & Newsletters',
      detail:
        'Our perspectives on key topics, mitigating risk and areas of opportunity for your business.',
    },
    {
      type: 'laws__bg',
      urlSlug: loggedIn ? 'laws-and-regulations' : null,
      title: 'Laws and Regulations',
      detail:
        'Remain at the forefront of key regulatory issues that guide your business.',
    },
    {
      type: 'webinars__bg',
      urlSlug: 'webinars',
      title: 'Webinars',
      detail: 'Our webinars are available at your convenience.',
      className: 'webinar',
    },
  ];

  const data = home ? dataHome : dataRegular;

  const handleClick = (type) => {
    // console.log(slug);
    if (!type) return history.push('/signin');
    if (!slug) return;
    history.push(`/${home ? 'home' : 'category'}/${slug}/${type}`);
  };

  return (
    <div className="imageGallery">
      {data.map((entry, index) => {
        if (!loggedIn && entry.title === 'Webinars') return null;
        return (
          <AnimatedNews
            key={index}
            title={entry.title}
            detail={entry.detail}
            type={entry.type}
            urlSlug={entry.urlSlug}
            onClick={() => handleClick(entry.urlSlug)}
            small={small}
            className={`${entry.className ? entry.className : ''}`}
          />

          // <div
          //   key={index}
          //   className={` image_gallery ${entry.type} ${
          //     small ? "small__image" : ""
          //   } main_container`}
          //   onClick={() => handleClick(entry.urlSlug)}
          // >
          //   <div className="imageGallery__content">
          //     {" "}
          //     <div className="test_animate">
          //       <span className="imageGallery__heading">{entry.title}</span>
          //       <p className="imageGallery__details">{entry.detail}</p>
          //     </div>
          //   </div>
          // </div>
        );
      })}
    </div>
  );
};

export default withRouter(ImageGallery);
