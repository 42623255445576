import React, { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";

import DefaultVidPoster from "../../assets/img/video-poster.jpeg";

import "react-modal-video/scss/modal-video.scss";

import "./webinarCard.scss";

const WebinarCard = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { title, videoUrl, videoPoster, date } = props;

  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add("sidebar__opened");
    } else {
      document.body.classList.remove("sidebar__opened");
    }
  }, [modalIsOpen]);

  return (
    <>
      <div className="webinarcard" onClick={() => setModalIsOpen(true)}>
        <div className="webinarcard__container">
          <div className="webinarcard__img__container">
            <img src={videoPoster || DefaultVidPoster} alt="webinar" />
          </div>
          <div className="webinarcard__detail">
            <div className="webinarcard__demo">
              <h4>{title}</h4>
              <span>{date}</span>
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={modalIsOpen}
        url={videoUrl}
        onClose={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default WebinarCard;
