import React, { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";

import NewsCard from "../NewsCard/NewsCard";

import "./PaginateContainer.scss";

const PaginateContainer = ({ data, handlePageClick, type }) => {
  const childRef = useRef();
  const isInitialMount = useRef(true);

  const handleScroll = () => {
    setTimeout(() => {
      childRef.current &&
        childRef.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleScroll();
    }
  }, [data]);

  return (
    <>
      <div className="paginate__container" ref={childRef}>
        {data && data[type].length > 0 ? (
          <>
            {" "}
            {data[type].map((content, index) => {
              if (type === "thoughtLeaderships") {
                return (
                  <NewsCard
                    key={content.id}
                    {...content}
                    contentType="thought"
                  />
                );
              } else {
                if (index === 0) {
                  return null;
                } else {
                  return (
                    <NewsCard
                      key={content.id}
                      {...content}
                      contentType="newsletter"
                    />
                  );
                }
              }
            })}
          </>
        ) : (
          <em> Not available yet!</em>
        )}
      </div>

      <ReactPaginate
        previousLabel={"prev"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={data ? Math.ceil(data.totalElement / 12) : 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination myCustomPagination"}
        activeClassName={"active"}
        disabledClassName={"disClass"}
      />
    </>
  );
};

export default PaginateContainer;
